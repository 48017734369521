import React from 'react';
import { Select } from 'antd';
import { useState } from 'react';
import { useGetCustomerSurvivalQuery } from '../../redux/api/customerAnalytics';
import PlotContainer from '../PlotContainer';
import { useSelector } from 'react-redux';
import TooltipContainer from '../../components/UI/TooltipContainer';
const CustomerSurvival = () => {

    const [filterType, setFilterType] = useState('All');
    const [cohortMonth, setCohortMonth] = useState(3);
    const selectedClient = useSelector((state) => state.genericReporting.selectedClient);

    return (
        <div className='main-content-container'>
            <div className='flexible-container'>
                <div className='flex-row'>
                    <div>
                        <h2>Filter By <TooltipContainer title='Filter By'></TooltipContainer></h2>
                        <Select style={{
                            'width': '10vw'
                        }}
                            options={
                                [
                                    { label: 'All', value: 'All' },
                                    { label: 'OTP', value: 'OTP' },
                                    { label: 'Subscription', value: 'Subscription' }
                                ]
                            }
                            onChange={
                                (value) => {
                                    setFilterType(value);
                                }
                            }
                            value={filterType}
                        ></Select>
                    </div>
                    <div>
                        <h2>Cohort Month <TooltipContainer title='Cohort Month'></TooltipContainer></h2>
                        <Select style={{
                            'width': '10vw'
                        }}
                            options={
                                [
                                    { label: '1', value: 1 },
                                    { label: '3', value: 3 },
                                    { label: '6', value: 6 },
                                    { label: '9', value: 9 },
                                    { label: '12', value: 12 },
                                    { label: '15', value: 15 },
                                    { label: '18', value: 18 }
                                ]
                            }
                            onChange={
                                (value) => {
                                    setCohortMonth(value);
                                }
                            }
                            value={cohortMonth}
                        ></Select>
                    </div>
                </div>
            </div>
            <div className='flexible-container' style={{
                'width': '95vw',
                'height': '60vh',
            }}>
                <PlotContainer tooltipTitle='Cohorted Retention' useApi={useGetCustomerSurvivalQuery} useApiArgs={{
                    filterType: filterType,
                    client: selectedClient,
                    cohortMonth: cohortMonth
                }} />
            </div>
        </div>
    );
};

export default CustomerSurvival;