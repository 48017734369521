import React from 'react';
import { useSelector } from 'react-redux';
import DateGranularityDropdown from '../../components/UI/DateGranularityDropdown';
import { useGetImpactOverTimeQuery, useGetDriverSplitQuery, useGetImpactOverviewTableQuery } from '../../redux/api/compassProject';
import PlotContainer from '../PlotContainer';
import TableContainer from '../TableContainer';
import '../containerStyles.css';

function FullChannelImpjactNCac() {

    const selectedClient = useSelector((state) => state.genericReporting.selectedClient);
    const selectedStartDate = useSelector((state) => state.genericReporting.startDate);
    const selectedEndDate = useSelector((state) => state.genericReporting.endDate);
    const selectedDateGranularity = useSelector((state) => state.genericReporting.dateGranularity);
    const selectedDrivers = useSelector((state) => state.compass.drivers);

    return (
        <div>
            <div className='full-width-container' style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
            }}>
                <div style={{
                    width: '25%',
                    height: '100%',
                }}>
                    <PlotContainer useApi={useGetDriverSplitQuery} useApiArgs={{
                        client_name: selectedClient,
                        start_date: selectedStartDate,
                        end_date: selectedEndDate,
                        granularity: selectedDateGranularity,
                        drivers: selectedDrivers
                    }} />
                </div>
                <hr></hr>
                <div style={{
                    width: '75%',
                    height: '100%',
                }}>
                    <PlotContainer useApi={useGetImpactOverTimeQuery} useApiArgs={{
                        client_name: selectedClient,
                        start_date: selectedStartDate,
                        end_date: selectedEndDate,
                        granularity: selectedDateGranularity,
                        drivers: selectedDrivers
                    }} />
                </div>
            </div>
            <TableContainer useApi={useGetImpactOverviewTableQuery} apiParams={{
                client_name: selectedClient,
                start_date: selectedStartDate,
                end_date: selectedEndDate,
                granularity: selectedDateGranularity,
                drivers: selectedDrivers
            }} />
        </div>
    );
}

export default FullChannelImpjactNCac;