import React from 'react';
import { useSelector } from 'react-redux';
import PlotContainer from '../PlotContainer';
import ExecutiveSummaryBoxMetrics from '../ExecutiveSummary/ExecutiveSummaryBoxMetrics';
import { useGetArchiveOutreachGraphQuery } from '../../redux/api/archiveInfluencer';;

const InfluencerOutreachPage = () => {

    const selectedClient = useSelector((state) => state.genericReporting.selectedClient);
    const selectedStartDate = useSelector((state) => state.genericReporting.startDate);
    const selectedEndDate = useSelector((state) => state.genericReporting.endDate);
    const selectedDateGranularity = useSelector((state) => state.genericReporting.dateGranularity);
    const rollingWindow = useSelector((state) => state.genericReporting.rollingWindow);
    const isRolling = useSelector((state) => state.genericReporting.IsRolling);

    return (
        <div>
            <ExecutiveSummaryBoxMetrics isInfluencer={true} isOutreach={true}></ExecutiveSummaryBoxMetrics>
            <div className='full-width-container'>
                <PlotContainer useApi={useGetArchiveOutreachGraphQuery} tooltipTitle='Total Spend vs 7D Rolling Total Spend' useApiArgs={{
                    client: selectedClient,
                    startDate: selectedStartDate,
                    endDate: selectedEndDate,
                    granularity: selectedDateGranularity,
                    rollingWindow: rollingWindow,
                    isRolling: isRolling,
                }} />
            </div>
        </div>
    );
};

export default InfluencerOutreachPage