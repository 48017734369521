import React, { useState } from 'react';
import {
    useGetExtraCostsQuery,
    usePutExtraCostsMutation,
    useDeleteExtraCostsMutation,
} from '../../redux/api/extraCosts';
import { useSelector } from 'react-redux';
import { Table, Button, Form, Input, DatePicker, Typography, Space, message } from 'antd';
import 'antd/dist/reset.css';

const { Title } = Typography;

const ExtraCostSettingsPage = () => {
    const selectedClient = useSelector((state) => state.genericReporting.selectedClient);

    const { data: extraCosts, isLoading, isError } = useGetExtraCostsQuery({ client: selectedClient });
    const [putExtraCost] = usePutExtraCostsMutation();
    const [deleteExtraCost] = useDeleteExtraCostsMutation();

    const [form] = Form.useForm();

    const handleAddExtraCost = async (values) => {
        try {
            await putExtraCost({
                client: selectedClient,
                channel: values.channel,
                start_date: values.startDate.format('YYYY-MM-DD'),
                end_date: values.endDate.format('YYYY-MM-DD'),
                daily_amount: values.dailyAmount,
            });
            form.resetFields();
            message.success('Extra cost added successfully.');
        } catch (error) {
            message.error('Failed to add extra cost.');
        }
    };

    const handleDelete = async (record) => {
        try {
            await deleteExtraCost(record);
            message.success('Extra cost deleted successfully.');
        } catch (error) {
            message.error('Failed to delete extra cost.');
        }
    };

    const columns = [
        {
            title: 'Account',
            dataIndex: 'client',
            key: 'client',
        },
        {
            title: 'Channel',
            dataIndex: 'channel',
            key: 'channel',
        },
        {
            title: 'Start Date',
            dataIndex: 'start_date',
            key: 'start_date',
        },
        {
            title: 'End Date',
            dataIndex: 'end_date',
            key: 'end_date',
        },
        {
            title: 'Daily Amount',
            dataIndex: 'daily_amount',
            key: 'daily_amount',
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <Button danger onClick={() => handleDelete(record)}>
                    Delete
                </Button>
            ),
        },
    ];

    return (
        <div style={{ padding: '20px' }}>
            <Title level={2}>Extra Cost Settings</Title>

            {isLoading ? (
                <p>Loading...</p>
            ) : isError ? (
                <p>Error loading extra costs.</p>
            ) : (
                <Table
                    dataSource={extraCosts}
                    columns={columns}
                    rowKey="id"
                    pagination={{ pageSize: 5 }}
                    style={{ marginBottom: '20px' }}
                />
            )}

            <Title level={4}>Add Extra Cost</Title>
            <Form
                form={form}
                layout="inline"
                onFinish={handleAddExtraCost}
                style={{ marginBottom: '20px' }}
            >
                <Form.Item
                    name="channel"
                    rules={[{ required: true, message: 'Please enter the channel!' }]}
                >
                    <Input placeholder="Channel" />
                </Form.Item>

                <Form.Item
                    name="startDate"
                    rules={[{ required: true, message: 'Please select the start date!' }]}
                >
                    <DatePicker placeholder="Start Date" />
                </Form.Item>

                <Form.Item
                    name="endDate"
                    rules={[{ required: true, message: 'Please select the end date!' }]}
                >
                    <DatePicker placeholder="End Date" />
                </Form.Item>

                <Form.Item
                    name="dailyAmount"
                    rules={[
                        { required: true, message: 'Please enter the daily amount!' }
                    ]}
                >
                    <Input placeholder="Daily Amount" type="number" />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Add
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default ExtraCostSettingsPage;
