import React from 'react';
import { useSelector } from 'react-redux';
import PlotContainer from '../PlotContainer';
import '../containerStyles.css';
import ProductGroupConfigDropdown from '../ProductGroupConfigDropdown';
import { useGetNewReturningCustomersByCategoryQuery } from '../../redux/api/executiveSummary';

export default function ExecutiveSummaryNewReturningByConfig(
    {
        platform = 'Shopify'
    }
) {
    const selectedClient = useSelector((state) => state.genericReporting.selectedClient);
    const selectedStartDate = useSelector((state) => state.genericReporting.startDate);
    const selectedEndDate = useSelector((state) => state.genericReporting.endDate);
    const selectedDateGranularity = useSelector((state) => state.genericReporting.dateGranularity);
    const selectedConfig = useSelector((state) => state.genericReporting.selectedConfig);

    return (
        // Your JSX code goes here
        <div className='flexible-container' style={{}}>
            <div>
                <div style={{
                    'marginLeft': '1.2vw'
                }}>
                    <h3>Select a Configuration</h3>
                    <ProductGroupConfigDropdown platform={platform}></ProductGroupConfigDropdown>
                </div>
                <div className='full-width-container' style={{
                    'width': '100%',
                    'height': '80vh'
                }}>
                    <PlotContainer tooltipTitle='New vs. Returning Customers by Product Group' useApi={useGetNewReturningCustomersByCategoryQuery} useApiArgs={{
                        clientName: selectedClient,
                        selectedConfig: selectedConfig,
                        startDate: selectedStartDate,
                        endDate: selectedEndDate,
                        granularity: selectedDateGranularity,
                        platform: platform
                    }} />
                </div>
            </div>
        </div>
    );
}