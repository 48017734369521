import { useEffect, useState } from 'react';
import {
    useGetAdUsernamePairsQuery,
    useUpdateAdUsernamePairsMutation,
    useDeleteAdUsernamePairsMutation
} from '../../redux/api/whitelistedMediaComparison';
import { useSelector } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import { Select, Input, Button } from 'antd';
import '../containerStyles.css'

export default function WhitelistedMediaSettings({
    platform,
}) {
    const [rowData, setRowData] = useState([]);
    const [options, setOptions] = useState([]);
    const [selectedValue, setSelectedValue] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [addedOptions, setAddedOptions] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);

    const [updateAdUsernamePairs] = useUpdateAdUsernamePairsMutation();
    const [deleteAdUsernamePairs] = useDeleteAdUsernamePairsMutation();

    const { Option } = Select;

    function updateRows() {
        const adUsernamePairs = selectedRows.map((row) => {
            return {
                'ad_name': row.ad_name,
                'username': selectedValue
            };
        });

        updateAdUsernamePairs({
            client: selectedClient,
            platform: platform,
            adUsernamePairs: adUsernamePairs
        });
    }

    function unassignRows() {
        const adUsernamePairs = selectedRows.map((row) => {
            return {
                'ad_name': row.ad_name,
                'username': ''
            };
        });

        updateAdUsernamePairs({
            client: selectedClient,
            platform: platform,
            adUsernamePairs: adUsernamePairs
        });
    }

    function deleteSelected() {
        const adUsernamePairs = selectedRows.map((row) => {
            return {
                'ad_name': row.ad_name,
                'username': ''
            };
        });

        deleteAdUsernamePairs({
            client: selectedClient,
            platform: platform,
            adUsernamePairs: adUsernamePairs
        });
    }

    const columnDefs = [
        {
            headerName: 'Ad', field: 'ad_name', enableFilter: true, 'sortable': true,
            'filter': true,
            'resizable': true
        },
        {
            headerName: 'Username', field: 'username', enableFilter: true, 'sortable': true,
            'filter': true,
            'resizable': true
        },
        {
            headerName: 'IMG',
            field: 'image_url',
            cellRenderer: (params) => (
                <img src={params.value} alt="No Image Available In Api" style={{ width: 'auto', height: '100%' }} />
            ),
            enableFilter: true,
            sortable: true,
        },
        {
            "headerName": "Select",
            "field": "select",
            "checkboxSelection": true,
            "headerCheckboxSelection": true,
            "headerCheckboxSelectionFilteredOnly": true,
        }
    ];

    const selectedClient = useSelector((state) => state.genericReporting.selectedClient);
    const selectedStartDate = useSelector((state) => state.genericReporting.startDate);
    const selectedEndDate = useSelector((state) => state.genericReporting.endDate);

    const adUsernamePairs = useGetAdUsernamePairsQuery({
        client: selectedClient,
        startDate: selectedStartDate,
        endDate: selectedEndDate,
        platform: platform
    });

    useEffect(() => {
        if (adUsernamePairs.isSuccess) {
            setRowData(adUsernamePairs.data.rowData);
        }
    }
        , [adUsernamePairs]);

    useEffect(() => {
        if (rowData) {
            let options = [];
            rowData.forEach((row) => {
                if (!options.includes(row.username)) {
                    options.push(row.username);
                }
            });
            setOptions(options);
            if (addedOptions.length > 0) {
                const addedOptionsNotInOptions = addedOptions.filter((option) => !options.includes(option));
                setOptions([...options, ...addedOptionsNotInOptions]);
            }
        }
    }, [rowData]);

    return (
        <div>
            <h1>{platform} settings</h1>
            <div style={{
                'height': '60vh',
                'width': '60vw'
            }}>
                <AgGridReact
                    columnDefs={columnDefs}
                    rowData={rowData}
                    rowSelection='multiple'
                    gridOptions={
                        {
                            rowHeight: 100,
                        }
                    }
                    onSelectionChanged={
                        (event) => {
                            setSelectedRows(event.api.getSelectedRows());
                            console.log(event.api.getSelectedRows());
                        }
                    }
                />
            </div>
            <div className='flexible-container'>
                <Select
                    value={selectedValue}
                    style={{ width: '100%' }}
                    placeholder="Select an option or add new"
                    onSelect={
                        (value) => {
                            setSelectedValue(value);
                        }
                    }
                    dropdownRender={(menu) => (
                        <div>
                            {menu}
                            <div style={{ display: 'flex', padding: 8 }}>
                                <Input
                                    style={{ flex: 'auto', marginRight: 8 }}
                                    value={inputValue}
                                    onChange={(e) => setInputValue(e.target.value)}
                                    placeholder="Add new option"
                                />
                                <Button
                                    onClick={
                                        () => {
                                            setOptions([...options, inputValue]);
                                            setAddedOptions([...addedOptions, inputValue]);
                                            setInputValue('');
                                        }
                                    }
                                    style={{
                                        padding: '0 10px',
                                        cursor: 'pointer',
                                        border: '1px solid #d9d9d9',
                                        borderRadius: 4,
                                        background: '#fafafa',
                                    }}
                                >
                                    Add
                                </Button>
                            </div>
                        </div>
                    )}
                >
                    {options.map((option) => (
                        <Option key={option} value={option}>
                            {option}
                        </Option>
                    ))}
                </Select>
                <Button onClick={
                    () => {
                        updateRows();
                    }
                } style={{
                    'width': '100%',
                    'marginTop': '1vh'
                }}>
                    Update
                </Button>
                <Button onClick={unassignRows} style={{
                    'width': '100%',
                    'marginTop': '1vh'
                }}>
                    Unassign selected
                </Button>
                <Button onClick={deleteSelected} style={{
                    'width': '100%',
                    'marginTop': '1vh'
                }}>
                    Reset selected values to default
                </Button>
            </div>
        </div>
    );
}