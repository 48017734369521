import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from './baseQuery';

export const archiveInfluencerApi = createApi({
    reducerPath: 'archiveInfluencerApi',
    baseQuery: baseQuery,
    tagTypes: ['ArchiveInfluencer', 'InfluencerPosts'],
    endpoints: (builder) => ({
        getArchiveRowCount: builder.query({
            query: ({ client }) => '/influencer_archive/influencer_posts_count' + "?client=" + client,
        }),
        getInfluencerPosts: builder.query({
            query: ({ client, page, pageSize }) => '/influencer_archive/influencer_posts' + "?client=" + client + "&page=" + page + "&page_size=" + pageSize,
        }),
        getInfluencerContentCountPlot: builder.query({
            query: ({ client, startDate, endDate, granularity, isRolling, rollingWindow }) => '/influencer_archive/content_count_plot' + "?client=" + client + "&start_date=" + startDate + "&end_date=" + endDate + "&granularity=" + granularity + "&is_rolling=" + isRolling + "&rolling_window=" + rollingWindow,
        }),
        getInfluencerEngagementMetricsPlot: builder.query({
            query: ({ client, startDate, endDate, granularity, isRolling, rollingWindow }) => '/influencer_archive/engagement_metrics_plot' + "?client=" + client + "&start_date=" + startDate + "&end_date=" + endDate + "&granularity=" + granularity + "&is_rolling=" + isRolling + "&rolling_window=" + rollingWindow,
        }),
        getReachVsRollingReachPlot: builder.query({
            query: ({ client, startDate, endDate, granularity, rollingWindow }) => '/influencer_archive/reach_vs_rolling_reach_plot' + "?client=" + client + "&start_date=" + startDate + "&end_date=" + endDate + "&granularity=" + granularity + "&rolling_window=" + rollingWindow,
        }),
        getNewVsReturningReachPlot: builder.query({
            query: ({ client, startDate, endDate, granularity, isRolling, rollingWindow }) => '/influencer_archive/new_vs_returning_reach_plot' + "?client=" + client + "&start_date=" + startDate + "&end_date=" + endDate + "&granularity=" + granularity + "&is_rolling=" + isRolling + "&rolling_window=" + rollingWindow,
        }),
        getNewVsReturningContentPlot: builder.query({
            query: ({ client, startDate, endDate, granularity, isRolling, rollingWindow }) => '/influencer_archive/new_vs_returning_content_plot' + "?client=" + client + "&start_date=" + startDate + "&end_date=" + endDate + "&granularity=" + granularity + "&is_rolling=" + isRolling + "&rolling_window=" + rollingWindow,
        }),
        getReachByCreatorTypePlot: builder.query({
            query: ({ client, startDate, endDate, granularity, isRolling, rollingWindow }) => '/influencer_archive/reach_by_creator_type_plot' + "?client=" + client + "&start_date=" + startDate + "&end_date=" + endDate + "&granularity=" + granularity + "&is_rolling=" + isRolling + "&rolling_window=" + rollingWindow,
        }),
        getContentCountByCreatorTypePlot: builder.query({
            query: ({ client, startDate, endDate, granularity, isRolling, rollingWindow }) => '/influencer_archive/content_count_by_creator_type_plot' + "?client=" + client + "&start_date=" + startDate + "&end_date=" + endDate + "&granularity=" + granularity + "&is_rolling=" + isRolling + "&rolling_window=" + rollingWindow,
        }),
        getArchiveOutreachGraph: builder.query({
            query: ({ client, startDate, endDate, granularity, isRolling, rollingWindow }) => '/influencer_archive/outreach_graph' + "?client=" + client + "&start_date=" + startDate + "&end_date=" + endDate + "&granularity=" + granularity + "&is_rolling=" + isRolling + "&rolling_window=" + rollingWindow,
        }),
    })

});

export const {
    useGetArchiveOutreachGraphQuery,
    useGetContentCountByCreatorTypePlotQuery,
    useGetReachByCreatorTypePlotQuery,
    useGetNewVsReturningContentPlotQuery,
    useGetNewVsReturningReachPlotQuery,
    useGetReachVsRollingReachPlotQuery,
    useGetInfluencerEngagementMetricsPlotQuery,
    useGetInfluencerContentCountPlotQuery,
    useGetInfluencerPostsQuery,
    useGetArchiveRowCountQuery,
} = archiveInfluencerApi;

export default archiveInfluencerApi.reducer;