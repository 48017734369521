import React from 'react';
import { ConfigProvider, Carousel, Button } from 'antd';

const InfluencerContent = ({ contents }) => {

    function contentToImageOrVideo(content) {
        if (content['type'] == 'VIDEO') {
            // make it round
            return <video src={content['contentUrl']} style={{ width: '100%', borderRadius: '5px' }} controls muted></video>
        } else {
            return <img src={content['contentUrl']} style={{ width: '100%', borderRadius: '5px' }}></img>
        }
    }

    return (
        <div>
            <Carousel>
                {contents.map((content) => {
                    return (
                        <div>
                            {contentToImageOrVideo(content)}
                        </div>
                    );
                })}
            </Carousel>
        </div>
    );
};

export default InfluencerContent;