import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from "./baseQuery";

export const customerAnalyticsApi = createApi({
    reducerPath: 'customerAnalyticsApi',
    baseQuery: baseQuery,
    tagTypes: ['CustomerAnalytics'],
    endpoints: (builder) => ({
        getLtrLtv: builder.query({
            query: ({ client, ltrLtvType, filterType }) => '/ltr_ltv' + "?client_name=" + client + "&ltr_ltv_type=" + ltrLtvType + "&type=" + filterType,
        }),
        getCohortedRetention: builder.query({
            query: ({ client, filterType }) => '/cohorted_retention' + "?client_name=" + client + "&type=" + filterType,
        }),
        getCustomerSurvival: builder.query({
            query: ({ client, filterType, cohortMonth }) => '/customer_survival' + "?client_name=" + client + "&type=" + filterType + "&cohort_month=" + cohortMonth,
        }),
        getLtrByCategory: builder.query({
            query: ({ clientName, selectedConfig }) => '/ltr_by_category' + "?client_name=" + clientName + "&selected_config=" + selectedConfig,
        }),
        getCategoricalCohortRetention: builder.query({
            query: ({ client, selectedConfig, type }) => '/cohorted_retention_by_category' + "?client_name=" + client + "&selected_config=" + selectedConfig + "&type=" + type,
        }),
        getCategoricalCustomerSurvival: builder.query({
            query: ({ client, selectedConfig, type, cohortMonth }) => '/customer_survival_by_category' + "?client_name=" + client + "&selected_config=" + selectedConfig + "&type=" + type + "&cohort_month=" + cohortMonth,
        }),
        getLtvVsCac: builder.query({
            query: ({ client, type }) => {
                return '/ltv_vs_cac' + "?client_name=" + client + "&type=" + type;
            }
        }),
    })
});

export const {
    useGetCustomerSurvivalQuery,
    useGetCategoricalCohortRetentionQuery,
    useGetCategoricalCustomerSurvivalQuery,
    useGetLtrLtvQuery,
    useGetCohortedRetentionQuery,
    useGetLtrByCategoryQuery,
    useGetLtvVsCacQuery,
} = customerAnalyticsApi;

export default customerAnalyticsApi.reducer;

