import React from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'antd'; // Import Ant Design Grid components
import { useGetUniqueMediaChannelsQuery, useGetSpendVsTargetChartQuery } from '../../redux/api/budgetTracker';
import PlotContainer from '../../containers/PlotContainer';

export default function ChannelStatusCharts() {
    const selectedClient = useSelector((state) => state.genericReporting.selectedClient);

    const { data: uniqueMediaChannels, isFetching: isUniqueMediaChannelsFetching } = useGetUniqueMediaChannelsQuery({
        client: selectedClient,
    });

    return (
        <div style={{ padding: '20px', 'width': '100%' }}>
            <Row gutter={[20, 20]} justify="center" style={{
                'width': '100%',
            }}>
                {uniqueMediaChannels &&
                    uniqueMediaChannels.map((channel, index) => (
                        <Col
                            key={index}
                            xs={24} // Full-width on extra-small screens
                            sm={24} // Full-width on small screens
                            md={24} // Half-width (2 per row) on medium screens
                            lg={12} // Half-width on large screens
                            style={{ height: '45vh' }} // Consistent chart height
                        >
                            <div style={{
                                'width': '100%',
                                'height': '100%',
                                'display': 'flex',
                                'justifyContent': 'center',
                                'alignItems': 'center',
                            }}>
                                <PlotContainer
                                    useApi={useGetSpendVsTargetChartQuery}
                                    useApiArgs={{
                                        client: selectedClient,
                                        channel: channel,
                                    }}
                                />
                            </div>
                        </Col>
                    ))}
            </Row>
        </div>
    );
}
