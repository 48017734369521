import React from 'react';
import { useSelector } from 'react-redux';
import ProductGroupConfigDropdown from "../ProductGroupConfigDropdown";
import TooltipContainer from "../../components/UI/TooltipContainer";
import { Select } from 'antd';
import { useState } from 'react';
import { useGetCategoricalCohortRetentionQuery } from '../../redux/api/customerAnalytics';
import PlotContainer from '../PlotContainer';

const CategoricalRetention = () => {

    const [filterType, setFilterType] = useState('All');
    const selectedClient = useSelector((state) => state.genericReporting.selectedClient);
    const selectedConfig = useSelector((state) => state.genericReporting.selectedConfig);

    return (
        <div className='main-content-container'>
            <div className='flexible-container flex-row'>
                <div style={{
                    'marginRight': '2vw'
                }}>
                    <h2>Filter By <TooltipContainer title='Filter By'></TooltipContainer></h2>
                    <Select style={{
                        'width': '10vw'
                    }}
                        options={
                            [
                                { label: 'All', value: 'All' },
                                { label: 'OTP', value: 'OTP' },
                                { label: 'Subscription', value: 'Subscription' }
                            ]
                        }
                        onChange={
                            (value) => {
                                setFilterType(value);
                            }
                        }
                        value={filterType}
                    ></Select>
                </div>
                <div>
                    <h2>Select a Configuration <TooltipContainer title='Select a Configuration'></TooltipContainer> </h2>
                    <ProductGroupConfigDropdown></ProductGroupConfigDropdown>
                </div>
            </div>
            <div className="flexible-container" style={{
                'width': '95vw',
                'height': '100vh',
            }}>
                <PlotContainer
                    useApi={useGetCategoricalCohortRetentionQuery}
                    useApiArgs={{ client: selectedClient, selectedConfig: selectedConfig, type: filterType }}
                ></PlotContainer>
            </div>

        </div>
    );
};

export default CategoricalRetention;