import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import '../containerStyles.css';
import { Select } from 'antd';
import GoogleAnalyticsFunnelingDropdowns from "./GoogleAnalyticsFunnelingDropdowns";
import { useState } from 'react';
import { useGetForwardIndicatorOptionsQuery, useGetForwardIndicatorsChartQuery } from "../../redux/api/gaFunneling";
import DateGranularityDropdown from "../../components/UI/DateGranularityDropdown";
import Plot from "react-plotly.js";
import PlotContainer from "../PlotContainer";
import TooltipContainer from "../../components/UI/TooltipContainer";

export default function ForwardIndicatorTab({
    isRolling, rollingWindow
}) {

    const selectedClient = useSelector((state) => state.genericReporting.selectedClient);
    const selectedStartDate = useSelector((state) => state.genericReporting.startDate);
    const selectedEndDate = useSelector((state) => state.genericReporting.endDate);
    const countryFilter = useSelector((state) => state.genericReporting.countryFilter);
    const gaDeviceCategoriesFilter = useSelector((state) => state.genericReporting.gaDeviceCategoriesFilter);
    const gaSourceFilter = useSelector((state) => state.genericReporting.gaSourceFilter);
    const aggregateBy = useSelector((state) => state.genericReporting.dateGranularity);
    const gaLandingPageFilter = useSelector((state) => state.genericReporting.gaLandingPageFilter);

    const forwardIndicatorOptions = useGetForwardIndicatorOptionsQuery({
        clientName: selectedClient,
    });

    const [multiChannelMetricOptions, setMultiChannelMetricOptions] = useState([]);
    const [selectedMultiChannelMetric, setSelectedMultiChannelMetric] = useState('Total Spend');

    const [gaMetricOptions, setGaMetricOptions] = useState([]);
    const [selectedGaMetric, setSelectedGaMetric] = useState('Sessions');

    useEffect(() => {
        if (forwardIndicatorOptions.data) {
            setMultiChannelMetricOptions(forwardIndicatorOptions.data.multichannel_options.map((metric) => {
                return { label: metric, value: metric };
            }));
            setGaMetricOptions(forwardIndicatorOptions.data.ga_options.map((metric) => {
                return { label: metric, value: metric };
            }));
        }
    }, [forwardIndicatorOptions.data]);

    return <div>
        <div className="flex-row" style={{
            'justifyContent': 'center',
        }}>
            <div className="quarter-page-container" style={{
                'display': 'flex',
                'flexDirection': 'column',
                'alignItems': 'center',
            }}>
                <h1 style={{
                    marginTop: '8vh'
                }}>Filter by Date Granularity <TooltipContainer title='Date Granularity'></TooltipContainer></h1>
                <DateGranularityDropdown></DateGranularityDropdown>
            </div>
            <div className="quarter-page-container" style={{
                'display': 'flex',
                'flexDirection': 'column',
                'alignItems': 'center',
            }}>
                <h1 style={{ marginTop: '8vh' }}>Multi Channel Metric <TooltipContainer title='Multi Channel Metric'></TooltipContainer></h1>
                <Select showSearch={true} placeholder='Select a Multi Channel Metric' options={multiChannelMetricOptions} value={selectedMultiChannelMetric} onChange={
                    (value) => setSelectedMultiChannelMetric(value)
                } style={{
                    width: '20vw'
                }}></Select>
            </div>
            <div className="half-page-container" style={{
                'justifyContent': 'center',
                'display': 'flex',
                'flexDirection': 'column',
                'alignItems': 'center',
            }}>
                <h1>Ga Metric <TooltipContainer title='GA Metric'></TooltipContainer></h1>
                <Select showSearch={true} options={gaMetricOptions} onChange={(value) => {
                    setSelectedGaMetric(value);
                }} value={selectedGaMetric} placeholder='Select a GA Metric' style={{
                    width: '20vw',
                }}></Select>
                <GoogleAnalyticsFunnelingDropdowns></GoogleAnalyticsFunnelingDropdowns>
            </div>
        </div>
        <div className="full-width-container">
            <PlotContainer tooltipTitle='Forward Indicators' useApi={useGetForwardIndicatorsChartQuery} useApiArgs={{
                clientName: selectedClient,
                startDate: selectedStartDate,
                endDate: selectedEndDate,
                country: countryFilter,
                deviceCategory: gaDeviceCategoriesFilter,
                trafficSource: gaSourceFilter,
                multichannelMetric: selectedMultiChannelMetric,
                gaMetric: selectedGaMetric,
                granularity: aggregateBy,
                isRolling: isRolling,
                rollingWindow: rollingWindow,
                landingPage: gaLandingPageFilter
            }}></PlotContainer>
        </div>
    </div>;
}