import React from 'react';
import { useSelector } from 'react-redux';
import GoogleAnalyticsFunnelingDropdowns from './GoogleAnalyticsFunnelingDropdowns';
import PlotContainer from '../PlotContainer';
import {
    useGaSessionsQuery, useGetTransactionsQuery, useGetViewProductRateQuery,
    useGetAddToCartRateQuery, useGetBeginCheckoutRateQuery, useGetTransactionRateQuery, useGetAllActionsPieChartQuery, useGetConversionRateQuery
} from '../../redux/api/gaFunneling';
import Plot from 'react-plotly.js';
import '../containerStyles.css';
import DateGranularityDropdown from '../../components/UI/DateGranularityDropdown';
import TooltipContainer from '../../components/UI/TooltipContainer';

const GoogleAnalyticsFunnelingCharts = ({
    isRolling, rollingWindow
}) => {

    const selectedClient = useSelector((state) => state.genericReporting.selectedClient);
    const selectedStartDate = useSelector((state) => state.genericReporting.startDate);
    const selectedEndDate = useSelector((state) => state.genericReporting.endDate);
    const countryFilter = useSelector((state) => state.genericReporting.countryFilter);
    const gaDeviceCategoriesFilter = useSelector((state) => state.genericReporting.gaDeviceCategoriesFilter);
    const gaSourceFilter = useSelector((state) => state.genericReporting.gaSourceFilter);
    const gaLandingPageFilter = useSelector((state) => state.genericReporting.gaLandingPageFilter);
    const granularity = useSelector((state) => state.genericReporting.dateGranularity);

    return (
        <div>
            <div className='flex-row'>
                <div>
                    <div className='forty-container-slim' style={{
                        'display': 'flex',
                        'flexDirection': 'row',
                        'height': '33.5vh',
                        'justifyContent': 'center',
                    }}>
                        <GoogleAnalyticsFunnelingDropdowns></GoogleAnalyticsFunnelingDropdowns>
                    </div>
                    <div className='flexible-container' style={
                        {
                            'display': 'flex',
                            'flexDirection': 'column',
                            'justifyContent': 'center',
                            'height': '15vh',
                            'alignItems': 'center',
                        }
                    }>
                        <h3>Date Granularity <TooltipContainer title='Date Granularity'></TooltipContainer></h3>
                        <br></br>
                        <DateGranularityDropdown></DateGranularityDropdown>
                    </div>
                </div>
                <div style={{
                    'width': '60vw',
                    'height': '50vh',
                    'backgroundColor': "var(--tertiary-color)",
                    'borderRadius': '10px',
                    'justifyContent': 'center',
                    "margin": "0.5rem",
                    "padding": "1rem",
                }}>
                    <PlotContainer tooltipTitle='Funneling Breakdown' useApi={useGetAllActionsPieChartQuery} useApiArgs={{
                        clientName: selectedClient,
                        startDate: selectedStartDate,
                        endDate: selectedEndDate,
                        country: countryFilter,
                        deviceCategory: gaDeviceCategoriesFilter,
                        trafficSource: gaSourceFilter,
                        landingPage: gaLandingPageFilter
                    }}></PlotContainer>
                </div>
            </div>
            <br></br>
            <div>
                <div className='flex-row'>
                    <div className='half-page-container'>
                        <PlotContainer tooltipTitle='Sessions by Date' useApi={useGaSessionsQuery} useApiArgs={{
                            clientName: selectedClient,
                            startDate: selectedStartDate,
                            endDate: selectedEndDate,
                            country: countryFilter,
                            deviceCategory: gaDeviceCategoriesFilter,
                            trafficSource: gaSourceFilter,
                            landingPage: gaLandingPageFilter,
                            granularity: granularity,
                            isRolling: isRolling,
                            rollingWindow: rollingWindow
                        }}></PlotContainer>
                    </div>
                    <div className='half-page-container'>
                        <PlotContainer tooltipTitle='Transactions by Date' useApi={useGetTransactionsQuery} useApiArgs={{
                            clientName: selectedClient,
                            startDate: selectedStartDate,
                            endDate: selectedEndDate,
                            country: countryFilter,
                            deviceCategory: gaDeviceCategoriesFilter,
                            landingPage: gaLandingPageFilter,
                            trafficSource: gaSourceFilter,
                            granularity: granularity,
                            isRolling: isRolling,
                            rollingWindow: rollingWindow
                        }}></PlotContainer>
                    </div>
                </div>
                <div className='full-width-container'>
                    <PlotContainer tooltipTitle='Conversion Rate by Date' useApi={useGetConversionRateQuery} useApiArgs={{
                        clientName: selectedClient,
                        startDate: selectedStartDate,
                        endDate: selectedEndDate,
                        country: countryFilter,
                        deviceCategory: gaDeviceCategoriesFilter,
                        landingPage: gaLandingPageFilter,
                        trafficSource: gaSourceFilter,
                        granularity: granularity,
                        isRolling: isRolling,
                        rollingWindow: rollingWindow
                    }}></PlotContainer>
                </div>
                <div className='flex-row'>
                    <div className='half-page-container'>
                        <PlotContainer tooltipTitle='View Product Rate by Date' useApi={useGetViewProductRateQuery} useApiArgs={{
                            clientName: selectedClient,
                            startDate: selectedStartDate,
                            endDate: selectedEndDate,
                            country: countryFilter,
                            deviceCategory: gaDeviceCategoriesFilter,
                            landingPage: gaLandingPageFilter,
                            trafficSource: gaSourceFilter,
                            granularity: granularity,
                            isRolling: isRolling,
                            rollingWindow: rollingWindow
                        }}></PlotContainer>
                    </div>
                    <div className='half-page-container'>
                        <PlotContainer tooltipTitle='Add to Cart Rate by Date' useApi={useGetAddToCartRateQuery} useApiArgs={{
                            clientName: selectedClient,
                            startDate: selectedStartDate,
                            endDate: selectedEndDate,
                            country: countryFilter,
                            deviceCategory: gaDeviceCategoriesFilter,
                            landingPage: gaLandingPageFilter,
                            trafficSource: gaSourceFilter,
                            granularity: granularity,
                            isRolling: isRolling,
                            rollingWindow: rollingWindow
                        }}></PlotContainer>
                    </div>
                </div>
                <div className='flex-row'>
                    <div className='half-page-container'>
                        <PlotContainer tooltipTitle='Begin Checkout Rate by Date' useApi={useGetBeginCheckoutRateQuery} useApiArgs={{
                            clientName: selectedClient,
                            startDate: selectedStartDate,
                            endDate: selectedEndDate,
                            country: countryFilter,
                            deviceCategory: gaDeviceCategoriesFilter,
                            landingPage: gaLandingPageFilter,
                            trafficSource: gaSourceFilter,
                            granularity: granularity,
                            isRolling: isRolling,
                            rollingWindow: rollingWindow
                        }}></PlotContainer>
                    </div>

                    <div className='half-page-container'>
                        <PlotContainer tooltipTitle='Transaction Rate by Date' useApi={useGetTransactionRateQuery} useApiArgs={{
                            clientName: selectedClient,
                            startDate: selectedStartDate,
                            endDate: selectedEndDate,
                            country: countryFilter,
                            deviceCategory: gaDeviceCategoriesFilter,
                            landingPage: gaLandingPageFilter,
                            trafficSource: gaSourceFilter,
                            granularity: granularity,
                            isRolling: isRolling,
                            rollingWindow: rollingWindow
                        }}></PlotContainer>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GoogleAnalyticsFunnelingCharts;