import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from "./baseQuery";

export const executiveSummaryApi = createApi({
    reducerPath: 'executiveSummaryApi',
    baseQuery: baseQuery,
    tagTypes: ['ExecutiveSummary'],
    endpoints: (builder) => ({
        getNewCustomerVsNcpa: builder.query({
            query: ({
                client,
                granularity,
                startDate,
                endDate,
                isRolling,
                rollingWindow,
                platform
            }) => '/executive_summary/new_customers_vs_ncpa_chart' + "?client=" + client + "&start_date=" + startDate + "&end_date=" + endDate + "&granularity=" + granularity + '&is_rolling=' + isRolling + '&rolling_window=' + rollingWindow + '&platform=' + platform,
            providesTags: ['ExecutiveSummary'],
        }),
        getNewSubsVsCpns: builder.query({
            query: ({
                client,
                granularity,
                startDate,
                endDate,
                isRolling,
                rollingWindow
            }) => '/executive_summary/new_subs_vs_cpns_chart' + "?client=" + client + "&start_date=" + startDate + "&end_date=" + endDate + "&granularity=" + granularity + "&is_rolling=" + isRolling + "&rolling_window=" + rollingWindow,
            providesTags: ['ExecutiveSummary'],
        }),
        getUniqueShopifyCountries: builder.query({
            query: ({ client }) => '/shopify_unique_countries' + "?client=" + client,
            providesTags: ['ExecutiveSummary'],
        }),
        getRevenueSplitByNewReturningPlot: builder.query({
            query: ({
                client,
                country,
                granularity,
                startDate,
                endDate
            }) => '/executive_summary/revenue_split_by_new_and_returning_plot' + "?client=" + client + "&start_date=" + startDate + "&end_date=" + endDate + "&country=" + country + "&granularity=" + granularity,
            providesTags: ['ExecutiveSummary'],
        }),
        getRollingSpend: builder.query({
            query: ({ client, startDate, endDate, granularity, rollingWindow, platform }) => '/executive_summary/rolling_spend' + "?client=" + client + "&start_date=" + startDate + "&end_date=" + endDate + "&granularity=" + granularity + "&rolling_window=" + rollingWindow + "&platform=" + platform,
            providesTags: ['ExecutiveSummary'],
        }),
        getAovTrend: builder.query({
            query: ({ client, startDate, endDate, granularity }) => '/executive_summary/aov_trend' + "?client=" + client + "&start_date=" + startDate + "&end_date=" + endDate + "&granularity=" + granularity,
            providesTags: ['ExecutiveSummary'],
        }),
        getNewCustomerVsNewSubsPercentage: builder.query({
            query: ({ client, startDate, endDate, granularity, platform }) => '/executive_summary/new_customer_vs_new_subs_percentage' + "?client=" + client + "&start_date=" + startDate + "&end_date=" + endDate + "&granularity=" + granularity + "&platform=" + platform,
        }),
        getNewReturningCustomersByCategory: builder.query({
            query({ clientName, selectedConfig, startDate, endDate, granularity, platform }) {
                return '/executive_summary/categorical_new_returning_customers' + "?client=" + clientName + "&product_group_config_name=" + selectedConfig + "&start_date=" + startDate + "&end_date=" + endDate + "&granularity=" + granularity + "&platform=" + platform;
            },
            providesTags: ['ExecutiveSummary'],
        }),
        getAmazonRevenuePlot: builder.query({
            query: ({ client, startDate, endDate, granularity, isRolling, rollingWindow }) => '/executive_summary_amazon/revenue_plot' + "?client=" + client + "&start_date=" + startDate + "&end_date=" + endDate + "&granularity=" + granularity + "&is_rolling=" + isRolling + "&rolling_window=" + rollingWindow,
        }),
        getAmazonOrdersPlot: builder.query({
            query: ({ client, startDate, endDate, granularity, isRolling, rollingWindow }) => '/executive_summary_amazon/orders_plot' + "?client=" + client + "&start_date=" + startDate + "&end_date=" + endDate + "&granularity=" + granularity + "&is_rolling=" + isRolling + "&rolling_window=" + rollingWindow,
        }),
        getAmazonAovPlot: builder.query({
            query: ({ client, startDate, endDate, granularity, isRolling, rollingWindow }) => '/executive_summary_amazon/aov_plot' + "?client=" + client + "&start_date=" + startDate + "&end_date=" + endDate + "&granularity=" + granularity + "&is_rolling=" + isRolling + "&rolling_window=" + rollingWindow,
        }),
        getAmazonDtcSpendVsSalesPlot: builder.query({
            query: ({ client, startDate, endDate, granularity, isRolling, rollingWindow }) => '/executive_summary_amazon/dtc_spend_vs_sales_plot' + "?client=" + client + "&start_date=" + startDate + "&end_date=" + endDate + "&granularity=" + granularity + "&is_rolling=" + isRolling + "&rolling_window=" + rollingWindow,
        }),
        getExecutiveSummaryAmazonRoasPlot: builder.query({
            query: ({ client, startDate, endDate, granularity, isRolling, rollingWindow }) => '/executive_summary_amazon/roas_plot' + "?client=" + client + "&start_date=" + startDate + "&end_date=" + endDate + "&granularity=" + granularity + "&is_rolling=" + isRolling + "&rolling_window=" + rollingWindow,
        }),
    }),
});

export const { useGetNewCustomerVsNcpaQuery,
    useGetNewSubsVsCpnsQuery,
    useGetRevenueSplitByNewReturningPlotQuery,
    useGetUniqueShopifyCountriesQuery,
    useGetRollingSpendQuery,
    useGetAovTrendQuery,
    useGetNewCustomerVsNewSubsPercentageQuery,
    useGetNewReturningCustomersByCategoryQuery,
    useGetAmazonRevenuePlotQuery,
    useGetAmazonOrdersPlotQuery,
    useGetAmazonAovPlotQuery,
    useGetAmazonDtcSpendVsSalesPlotQuery,
    useGetExecutiveSummaryAmazonRoasPlotQuery,
} = executiveSummaryApi;

export default executiveSummaryApi.reducer;