import React from 'react';
import { useSelector } from 'react-redux';

import { useGetBudgetBoxesQuery, useGetDailyTableChannelsQuery } from '../../redux/api/budgetTracker';
import BudgetBoxes from '../../components/BudgetPlanner/BudgetBoxes';
import TableContainer from '../../containers/TableContainer'
import ChannelStatusCharts from './ChannelStatusCharts';

const ChannelStatus = () => {

    const selectedClient = useSelector((state) => state.genericReporting.selectedClient);

    const { data: budgetBoxes, isFetching:
        isBudgetBoxesFetching,
        isError: isBudgetBoxesError } = useGetBudgetBoxesQuery({
            client: selectedClient,
        });

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '95vw',
            height: '100%',
            padding: '20px'
        }}>
            <BudgetBoxes data={budgetBoxes} isFetching={isBudgetBoxesFetching} isError={isBudgetBoxesError}></BudgetBoxes>
            <br></br>
            <ChannelStatusCharts></ChannelStatusCharts>
            <TableContainer width='90vw' useApi={useGetDailyTableChannelsQuery} apiParams={{ client: selectedClient }}></TableContainer>
            <br></br>
        </div>
    );
};

export default ChannelStatus;