import ProductGroupingConfigSelection from "./ProductGroupingConfigSelection";
import ProductGroupCreationAttributionPage from "./ProductGroupCreationAttributionPage";
import { useState } from "react";

export default function ProductGroupingPage({ platform = 'Shopify' }) {

    const [selectedConfig, setSelectedConfig] = useState(null);

    return (
        <div>
            <ProductGroupingConfigSelection platform={platform} selectedConfig={selectedConfig} setSelectedConfig={setSelectedConfig}></ProductGroupingConfigSelection>
            <ProductGroupCreationAttributionPage platform={platform} selectedConfig={selectedConfig}></ProductGroupCreationAttributionPage>
        </div>
    );
}