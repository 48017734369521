import React, { useState, useEffect } from 'react';
import { Table, Input, Button, message } from 'antd';
import {
    useGetBudgetCategoriesQuery,
    useUpdateBudgetMutation,
    useGetBudgetsQuery
} from '../../redux/api/budgetTracker';
import { useSelector } from 'react-redux';

const BudgetTrackerSettingsPage = () => {
    const selectedClient = useSelector((state) => state.genericReporting.selectedClient);
    const selectedStartDate = useSelector((state) => state.genericReporting.startDate);
    const selectedEndDate = useSelector((state) => state.genericReporting.endDate);

    const budgetCategories = useGetBudgetCategoriesQuery({
        client: selectedClient,
        startDate: selectedStartDate,
        endDate: selectedEndDate,
    });

    const budgets = useGetBudgetsQuery({
        client: selectedClient,
    });

    const [updateBudget] = useUpdateBudgetMutation();
    const [dataSource, setDataSource] = useState([]);
    const [editedBudgets, setEditedBudgets] = useState({});

    useEffect(() => {
        if (budgetCategories.data && budgets.data) {
            const tableData = budgetCategories.data.map((category) => ({
                key: category,
                channel: category,
                budget: budgets.data[category] || 0,
                deleted: budgets.data[category] === -1, // Check if the budget is already deleted
            }));
            setDataSource(tableData);
        }
    }, [budgetCategories, budgets]);

    const handleInputChange = (record, value) => {
        setEditedBudgets((prev) => ({
            ...prev,
            [record.channel]: parseFloat(value),
        }));
    };

    const handleDelete = (record) => {
        setDataSource((prev) =>
            prev.map((item) =>
                item.key === record.key
                    ? { ...item, budget: -1, deleted: true }
                    : item
            )
        );

        setEditedBudgets((prev) => ({
            ...prev,
            [record.channel]: -1,
        }));
    };

    const handleRestore = (record) => {
        setDataSource((prev) =>
            prev.map((item) =>
                item.key === record.key
                    ? { ...item, budget: 0, deleted: false }
                    : item
            )
        );

        setEditedBudgets((prev) => ({
            ...prev,
            [record.channel]: 0,
        }));
    };

    const handleSubmit = async () => {
        const updates = Object.entries(editedBudgets);

        try {
            for (const [channel, budget] of updates) {
                if (isNaN(budget)) {
                    throw new Error(`Invalid budget for channel: ${channel}`);
                }

                await updateBudget({
                    client: selectedClient,
                    channel,
                    budget,
                }).unwrap();
            }

            message.success('Budgets updated successfully.');
            setEditedBudgets({});
        } catch (error) {
            message.error(`Failed to update budgets: ${error.message}`);
        }
    };

    const columns = [
        {
            title: 'Channel',
            dataIndex: 'channel',
            key: 'channel',
            render: (text, record) =>
                record.deleted ? <del>{text}</del> : text,
        },
        {
            title: 'Target',
            dataIndex: 'budget',
            key: 'budget',
            render: (text, record) =>
                record.deleted ? (
                    <span>Deleted</span>
                ) : (
                    <Input
                        type="number"
                        defaultValue={text}
                        onChange={(e) => handleInputChange(record, e.target.value)}
                    />
                ),
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) =>
                record.deleted ? (
                    <Button type="link" onClick={() => handleRestore(record)}>
                        Restore
                    </Button>
                ) : (
                    <Button type="link" danger onClick={() => handleDelete(record)}>
                        Delete
                    </Button>
                ),
        },
    ];

    return (
        <div className='flex-column' style={{
            'alignItems': 'center',
            'justifyContent': 'center',
            'marginTop': '5vh',
        }}>
            <h1>Budget Planner Settings</h1>
            <Table
                dataSource={dataSource}
                columns={columns}
                rowClassName={(record) => (record.deleted ? 'deleted-row' : '')}
                pagination={false}
                style={{
                    'width': '50vw',
                    'margin': 'auto',
                }}
            />
            <Button type="primary" onClick={handleSubmit} style={{
                marginTop: '16px',
                marginBottom: '10vh',
                width: '10vw',
                alignSelf: 'center',
            }}>
                Submit
            </Button>
        </div>
    );
};

export default BudgetTrackerSettingsPage;
