import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDateGranularity } from '../../redux/store/genericReportingSlice';
import { Select } from 'antd';
import dayjs from 'dayjs';
import TooltipContainer from './TooltipContainer';

function DateGranularityDropdown({
    defaultValue = 'day'
}) {

    const dispatch = useDispatch();

    const currentGranularity = useSelector((state) => state.genericReporting.dateGranularity);

    useEffect(() => {



        dispatch(setDateGranularity(defaultValue));

    }, [defaultValue]);

    const options = [
        {
            label: 'Daily',
            value: 'day'
        },
        {
            label: 'Weekly',
            value: 'week'
        },
        {
            label: 'Monthly',
            value: 'month'
        },
        {
            label: 'Quarterly',
            value: 'quarter'
        },
        {
            label: 'Yearly',
            value: 'year'
        }
    ]

    return (
        <div>
            <Select placeholder='Select a date granularity' value={currentGranularity} style={{ width: 200 }} onChange={(val) => {
                dispatch(setDateGranularity(val));
            }} >
                {options.map((option) => {
                    return <Select.Option key={option.value} value={option.value}>{option.label}</Select.Option>
                })}
            </Select>
        </div>
    );
}

export default DateGranularityDropdown;