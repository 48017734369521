import { userApi } from './api/user'
import { shopifyReportingApi } from './api/shopifyReporting'
import { adsReportingApi } from './api/adsReporting';
import { claimsApi } from './api/claims';
import { compassProjectApi } from './api/compassProject';
import { gaFunnelingApi } from './api/gaFunneling';
import { genericReportingApi } from './api/genericReporting';
import { bulkUploaderApi } from './api/bulkUploader';
import { whitelistedMediaComparisonApi } from './api/whitelistedMediaComparison';
import { extraCostsApi } from './api/extraCosts';
import { budgetTrackerApi } from './api/budgetTracker';
import { executiveSummaryApi } from './api/executiveSummary';
import { productGroupingApi } from './api/productGrouping';
import { customerAnalyticsApi } from './api/customerAnalytics';
import { archiveInfluencerApi } from './api/archiveInfluencer';

const combinedApiMiddleware = [
    adsReportingApi.middleware,
    userApi.middleware,
    shopifyReportingApi.middleware,
    claimsApi.middleware,
    compassProjectApi.middleware,
    gaFunnelingApi.middleware,
    genericReportingApi.middleware,
    bulkUploaderApi.middleware,
    whitelistedMediaComparisonApi.middleware,
    extraCostsApi.middleware,
    budgetTrackerApi.middleware,
    executiveSummaryApi.middleware,
    productGroupingApi.middleware,
    customerAnalyticsApi.middleware,
    productGroupingApi.middleware,
    customerAnalyticsApi.middleware,
    archiveInfluencerApi.middleware,
];

export default combinedApiMiddleware;
