import TableContainer from "../TableContainer"
import { useSetBoxMetricOptionsMutation, useGetBoxMetricOptionsQuery, useDeleteBoxMetricOptionsMutation, useGetBoxMetricOptionsTableQuery } from "../../redux/api/genericReporting"
import { useSelector } from "react-redux";
import { Button, Input, Select, Spin } from "antd";
import { useState } from "react";

export default function BoxMetricSettings({
    platform
}) {

    const selectedClient = useSelector((state) => state.genericReporting.selectedClient);
    const boxMetricOptions = useGetBoxMetricOptionsQuery({ client: selectedClient, 'platform': platform });
    const [setBoxMetricOptions] = useSetBoxMetricOptionsMutation();
    const [deleteBoxMetricOptions] = useDeleteBoxMetricOptionsMutation();

    const [selectedOption, setSelectedOption] = useState();
    const [selectedPriority, setSelectedPriority] = useState(1);

    return (
        <div className='flex-row' style={{
            'justifyContent': 'space-between',
            'width': '90vw'
        }}>
            <TableContainer useApi={useGetBoxMetricOptionsTableQuery}
                apiParams={{ client: selectedClient, 'platform': platform }}
                width="26.2vw"
            ></TableContainer>
            <div>
                {boxMetricOptions.status === 'fulfilled' ? <div>

                    <h3>Box Metric Options</h3>
                    <Select options={
                        boxMetricOptions.data.map((option) => {
                            return { value: option, label: option }
                        })
                    } mode="tag" style={{
                        width: '26.2vw'
                    }} onChange={(value) => {
                        setSelectedOption(value)
                    }}
                        placeholder="Select Box Metric Options"
                    />
                    <Input style={{ width: '26.2vw', 'margin': '1vw' }} placeholder="Priority" min='1' type='number' value={selectedPriority} onChange={(e) => {
                        setSelectedPriority(e.target.value)
                    }}></Input>
                    <br></br>
                    <Button style={{
                        'marginRight': '0.5vw'
                    }} onClick={() => {
                        setBoxMetricOptions(
                            {
                                client: selectedClient,
                                metricTitle: selectedOption,
                                orderNumber: selectedPriority,
                                'platform': platform
                            }
                        )
                    }
                    }>Add Option</Button>
                    <Button onClick={() => {
                        deleteBoxMetricOptions(
                            {
                                client: selectedClient,
                                metricTitle: selectedOption,
                                orderNumber: selectedPriority,
                                'platform': platform
                            }
                        )
                    }
                    }>Delete Option</Button>
                </div>
                    : <Spin></Spin>
                }
            </div>
        </div>
    )
}