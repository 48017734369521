import React from 'react';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import TooltipContainer from './TooltipContainer';

function MetricBox(props) {

    const isPopPositive = props.popValue > 0;
    const isInverted = props.isInverted;
    const randomBoolean = Math.random() < 0.5;

    var color = null;
    if (isInverted) {
        if (isPopPositive) {
            color = '#ff9999';
        }
        if (!isPopPositive) {
            color = '#99ffa3';
        }
    }
    else {
        if (isPopPositive) {
            color = '#99ffa3';
        }
        if (!isPopPositive) {
            color = '#ff9999';
        }
    }


    return (
        <div
            className='small-info-container'
            style={{
                'backgroundColor': props.fill,
                'animation': randomBoolean ? 'slideInLeft 1.5s' : 'slideInRight 1.5s',
            }
            }
        >
            {props.ignoreTooltips ? <></> :
                <TooltipContainer title={props.title} style={{
                    'alignSelf': 'flex-end',
                    'margin': '0',
                    'width': '0.8vw',
                    'height': '2.0vh',
                }}></TooltipContainer>
            }
            <div style={{
                'fontSize': '1.2rem',
                'fontWeight': 'bold',
            }}> {props.value} </div>
            <div style={{
                'fontSize': '0.8rem',
                'fontWeight': 'bold'
            }}>{props.title}</div>
            <div style={{
                'display': 'flex',
                'flexDirection': 'row',
                'justifyContent': 'left',
                'marginTop': '0.8vh',
            }}>
                {(props.popValue !== null && props.popValue !== undefined && !isNaN(props.popValue)) && props.popValue != 0 && <div style={{
                    'backgroundColor': color,
                    'borderRadius': '0.5rem',
                    'padding': '0.2rem',
                    'fontSize': '1.0rem',
                    'fontWeight': 'bold',
                    'maxWidth': '8vw',
                    'textAlign': 'left',
                }}> {props.popValue}% {isPopPositive ? <ArrowUpOutlined></ArrowUpOutlined> : <ArrowDownOutlined></ArrowDownOutlined>} </div>
                }
            </div>
        </div>
    );
}

export default MetricBox;