import Tooltips from "../../Tooltips"
import { Tooltip } from "antd"
import React from "react"
import { InfoCircleOutlined } from "@ant-design/icons";
import { Modal } from "antd";

export default function TooltipContainer({
    style = {
        'alignSelf': 'flex-end',
        'margin': '0',
        'width': '0.8vw',
        'height': '2.0vh',
        'display': 'inline',
    },
    title,
    invertColor = false,
}) {

    const [isModalVisible, setIsModalVisible] = React.useState(false);
    const data = Tooltips[title];

    return (
        <div style={style} className='info-box'>
            <Tooltip
                overlayStyle={{
                    'minWidth': '30vw',
                }}
                onClick={() => setIsModalVisible(true)}
                title={
                    <>
                        <h3 style={{
                            'color': 'white',
                            'marginTop': '0',
                        }}>
                            {title}
                        </h3>
                        <p style={{
                            'color': 'white',
                            'marginBottom': '0',
                        }}>
                            {data}
                        </p>
                    </>
                } >
                <InfoCircleOutlined style={{
                    'color': invertColor ? 'white' : 'black',
                    'fontSize': '1.0rem',
                }} />
            </Tooltip>
            <Modal title={title} open={isModalVisible} onOk={() => setIsModalVisible(false)} onCancel={() => setIsModalVisible(false)} footer={null} >
                <div style={{
                    'display': 'flex',
                    'flexDirection': 'column',
                    'justifyContent': 'center',
                    'alignItems': 'center',
                }}>
                    <p>{data}</p>
                </div>
            </Modal>
        </div >
    )
}