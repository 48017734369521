import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from "./baseQuery";

export const productGroupingApi = createApi({
    reducerPath: 'productGroupingApi',
    baseQuery: baseQuery,
    tagTypes: [
        'ProductGroupConfig',
        'ProductGroups'
    ],
    endpoints: (builder) => ({
        createProductGroupConfig: builder.mutation({
            query: ({
                client,
                configName,
                platform
            }) => ({
                url: '/settings/product_group_config' + "?client=" + client + "&config_name=" + configName + "&platform=" + platform,
                method: 'PUT',
            }),
            invalidatesTags: ['ProductGroupConfig'],
        }),
        deleteProductGroup: builder.mutation({
            query: ({
                client,
                configName,
                productGroupName,
                platform
            }) => ({
                url: '/settings/product_group' + "?client=" + client + "&config_name=" + configName + "&product_group_name=" + productGroupName + "&platform=" + platform,
                method: 'DELETE',
            }),
            invalidatesTags: ['ProductGroups'],
        }),
        listProductGroupConfigs: builder.query({
            query: ({ client, platform }) => '/settings/product_group_configs' + "?client=" + client + "&platform=" + platform,
            providesTags: ['ProductGroupConfig'],
        }),
        activateProductGroupConfig: builder.mutation({
            query: ({
                client,
                configName,
                platform
            }) => ({
                url: '/settings/activate_product_group_config' + "?client=" + client + "&config_name=" + configName + "&platform=" + platform,
                method: 'PUT',
            }),
            invalidatesTags: ['ProductGroupConfig'],
        }),
        getActiveProductGroupConfig: builder.query({
            query: ({ client, platform }) => '/settings/active_product_group_config' + "?client=" + client + "&platform=" + platform,
            providesTags: ['ProductGroupConfig'],
        }),
        deleteProductGroupConfig: builder.mutation({
            query: ({
                client,
                configName,
                platform
            }) => ({
                url: '/settings/product_group_config' + "?client=" + client + "&config_name=" + configName + "&platform=" + platform,
                method: 'DELETE',
            }),
            invalidatesTags: ['ProductGroupConfig'],
        }),
        addAProductGroup: builder.mutation({
            query: ({
                client,
                configName,
                productGroup,
                platform
            }) => ({
                url: '/settings/product_group' + "?client=" + client + "&config_name=" + configName + "&product_group_name=" + productGroup + "&platform=" + platform,
                method: 'PUT',
            }),
            invalidatesTags: ['ProductGroups'],
        }),
        getProductsTable: builder.query({
            query: ({ client, configName, startDate, endDate, platform }) => '/settings/product_table' + "?client=" + client + "&config_name=" + configName + "&start_date=" + startDate + "&end_date=" + endDate + "&platform=" + platform,
            providesTags: ['ProductGroups'],
        }),
        getProductGroups: builder.query({
            query: ({ client, configName, platform }) => '/settings/product_groups' + "?client=" + client + "&config_name=" + configName + '&platform=' + platform,
            providesTags: ['ProductGroups'],
        }),
        assignToProductGroup: builder.mutation({
            query: ({
                client,
                configName,
                productGroup,
                products,
                platform
            }) => ({
                url: '/settings/assign_to_product_group' + "?client=" + client + "&config_name=" + configName + "&product_group_name=" + productGroup + '&platform=' + platform,
                method: 'PUT',
                body: {
                    products: products
                }
            }),
            invalidatesTags: ['ProductGroups'],
        }),
    })
});

export const {
    useCreateProductGroupConfigMutation,
    useListProductGroupConfigsQuery,
    useActivateProductGroupConfigMutation,
    useGetActiveProductGroupConfigQuery,
    useDeleteProductGroupConfigMutation,
    useAddAProductGroupMutation,
    useGetProductsTableQuery,
    useGetProductGroupsQuery,
    useAssignToProductGroupMutation,
    useDeleteProductGroupMutation,
} = productGroupingApi;

export default productGroupingApi.reducer;