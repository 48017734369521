import React from 'react';
import Upload from 'antd/es/upload/Upload';
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';
import './compassInputs.css';
import { useSelector } from 'react-redux';
import { Button, Spin } from 'antd';
import axios from 'axios';
import { useInvalidateDataTablesMutation } from '../../redux/api/compassProject';

const CompassInputs = () => {

    const [inputsFile, setInputsFile] = React.useState(null);
    const [outputFile, setOutputFile] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [response, setResponse] = React.useState(<></>);

    const [invalidateDataTables] = useInvalidateDataTablesMutation();

    const selectedProject = useSelector((state) => state.compass.selectedProject);
    const apiToken = useSelector((state) => state.oauth.jwt_token);

    function getUpload({ title, setFunction }) {
        return (
            <div style={{
                'justifyContent': 'center',
                'display': 'flex',
                'flexDirection': 'column',
                'textAlign': 'center',
                'margin': '2rem'
            }}>
                <h3>Upload {title}</h3>
                <Upload multiple='false' listType='text' beforeUpload={(file) => {
                    setFunction(file);
                    return false;
                }
                } >
                    <div className='uploadDiv'>
                        <button style={{ border: 0, background: 'none', cursor: 'grab' }} type="button">
                            <PlusOutlined />
                            <div>Upload</div>
                        </button>
                    </div>
                </Upload>
            </div>
        );
    }

    function onSubmit() {




        setLoading(true);

        var bodyFormData = new FormData();
        bodyFormData.append('compass_input', inputsFile);
        bodyFormData.append('compass_output', outputFile);

        // using axios because the rtk refuses to acknowledge that multipart form data exists
        axios.post(process.env.REACT_APP_API_URL + '/compass_upload_files?project_id=' + selectedProject.project_id, bodyFormData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + apiToken
            }
        }).then((response) => {

            setResponse(<div>Files uploaded successfully</div>);
            setLoading(false);
        }
        ).catch((error) => {

            setResponse(<div>Failed to upload files</div>);
            setLoading(false);
        }
        );

        invalidateDataTables();

    }

    return (
        // JSX code for your component's UI goes here
        <div style={{
            'display': 'flex',
            'flexDirection': 'row',

        }}>
            {getUpload({ title: 'Input File', setFunction: setInputsFile })}
            {getUpload({ title: 'Output File', setFunction: setOutputFile })}
            <div style={{
                'justifyContent': 'center',
                'display': 'flex',
                'flexDirection': 'column',
                'textAlign': 'center',
                'margin': '2rem'
            }}>
                {loading ? <Spin /> : <Button onClick={onSubmit}>Submit Data</Button>}
                {response}
            </div>
        </div>
    );
};

export default CompassInputs;