import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from './baseQuery';

export const gaFunnelingApi = createApi({
    reducerPath: 'gaFunnelingApi',
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        gaDeviceCategories: builder.query({
            query: (clientName) => 'google_analytics/device_categories?client_name=' + clientName,
        }),
        gaTop10Sources: builder.query({
            query: (clientName) => 'google_analytics/top_10_sources?client_name=' + clientName,
        }),
        gaCountries: builder.query({
            query: (clientName) => 'google_analytics/countries?client_name=' + clientName,
        }),
        gaSessions: builder.query({
            query: ({ clientName, startDate, endDate, trafficSource, deviceCategory, landingPage, country, granularity, isRolling, rollingWindow }) =>
                'google_analytics/sessions?client_name=' + clientName + '&start_date=' + startDate + '&end_date=' + endDate + '&traffic_source=' + trafficSource + '&device_category=' + deviceCategory + '&landing_page=' + landingPage + '&country=' + country + '&granularity=' + granularity + '&is_rolling=' + isRolling + '&rolling_window=' + rollingWindow,
        }),
        getTransactions: builder.query({
            query: ({ clientName, startDate, endDate, trafficSource, deviceCategory, landingPage, country, granularity, isRolling, rollingWindow }) =>
                'google_analytics/transactions?client_name=' + clientName + '&start_date=' + startDate + '&end_date=' + endDate + '&traffic_source=' + trafficSource + '&device_category=' + deviceCategory + '&landing_page=' + landingPage + '&country=' + country + '&granularity=' + granularity + '&is_rolling=' + isRolling + '&rolling_window=' + rollingWindow
        }),
        getViewProductRate: builder.query({
            query: ({ clientName, startDate, endDate, trafficSource, deviceCategory, landingPage, country, granularity, isRolling, rollingWindow }) =>
                'google_analytics/view_product_rate?client_name=' + clientName + '&start_date=' + startDate + '&end_date=' + endDate + '&traffic_source=' + trafficSource + '&device_category=' + deviceCategory + '&landing_page=' + landingPage + '&country=' + country + '&granularity=' + granularity + '&is_rolling=' + isRolling + '&rolling_window=' + rollingWindow
        }),
        getAddToCartRate: builder.query({
            query: ({ clientName, startDate, endDate, trafficSource, deviceCategory, landingPage, country, granularity, isRolling, rollingWindow }) =>
                'google_analytics/add_to_cart_rate?client_name=' + clientName + '&start_date=' + startDate + '&end_date=' + endDate + '&traffic_source=' + trafficSource + '&device_category=' + deviceCategory + '&landing_page=' + landingPage + '&country=' + country + '&granularity=' + granularity + '&is_rolling=' + isRolling + '&rolling_window=' + rollingWindow
        }),
        getBeginCheckoutRate: builder.query({
            query: ({ clientName, startDate, endDate, trafficSource, deviceCategory, landingPage, country, granularity, isRolling, rollingWindow }) =>
                'google_analytics/begin_checkout_rate?client_name=' + clientName + '&start_date=' + startDate + '&end_date=' + endDate + '&traffic_source=' + trafficSource + '&device_category=' + deviceCategory + '&landing_page=' + landingPage + '&country=' + country + '&granularity=' + granularity + '&is_rolling=' + isRolling + '&rolling_window=' + rollingWindow
        }),
        getTransactionRate: builder.query({
            query: ({ clientName, startDate, endDate, trafficSource, deviceCategory, landingPage, country, granularity, isRolling, rollingWindow }) =>
                'google_analytics/transaction_rate?client_name=' + clientName + '&start_date=' + startDate + '&end_date=' + endDate + '&traffic_source=' + trafficSource + '&device_category=' + deviceCategory + '&landing_page=' + landingPage + '&country=' + country + '&granularity=' + granularity + '&is_rolling=' + isRolling + '&rolling_window=' + rollingWindow
        }),
        getAllActionsPieChart: builder.query({
            query: ({ clientName, startDate, endDate, trafficSource, deviceCategory, landingPage, country, granularity }) =>
                'google_analytics/all_actions_pie_chart?client_name=' + clientName + '&start_date=' + startDate + '&end_date=' + endDate + '&traffic_source=' + trafficSource + '&device_category=' + deviceCategory + '&landing_page=' + landingPage + '&country=' + country + '&granularity=' + granularity
        }),
        getConversionRate: builder.query({
            query: ({ clientName, startDate, endDate, trafficSource, deviceCategory, landingPage, country, granularity, isRolling, rollingWindow }) =>
                'google_analytics/conversion_rate?client_name=' + clientName + '&start_date=' + startDate + '&end_date=' + endDate + '&traffic_source=' + trafficSource + '&device_category=' + deviceCategory + '&landing_page=' + landingPage + '&country=' + country + '&granularity=' + granularity + '&is_rolling=' + isRolling + '&rolling_window=' + rollingWindow
        }),
        getForwardIndicatorOptions: builder.query({
            query: ({ clientName }) => 'google_analytics/forward_indicator_options?client=' + clientName
        }),
        getForwardIndicatorsChart: builder.query({
            query: ({ clientName, startDate, endDate, trafficSource, deviceCategory, landingPage, country, granularity, multichannelMetric, gaMetric, isRolling, rollingWindow }) =>
                'google_analytics/forward_indicators?client=' + clientName + '&start_date=' + startDate + '&end_date=' + endDate + '&traffic_source=' + trafficSource + '&device_category=' + deviceCategory + '&landing_page=' + landingPage + '&country=' + country + '&granularity=' + granularity + '&multichannel_metric=' + multichannelMetric + '&ga_metric=' + gaMetric + '&is_rolling=' + isRolling + '&rolling_window=' + rollingWindow
        }),
        getCheckIsUsingNewGaTable: builder.query({
            query: (clientName) => 'google_analytics/is_using_new_ga_table?client=' + clientName
        }),
        getTopLandingPages: builder.query({
            query: (clientName) =>
                'google_analytics/top_landing_pages?client=' + clientName
        }),
    }),
})

export const {
    useGaDeviceCategoriesQuery,
    useGaTop10SourcesQuery,
    useGaCountriesQuery,
    useGaSessionsQuery,
    useGetTransactionsQuery,
    useGetViewProductRateQuery,
    useGetAddToCartRateQuery,
    useGetBeginCheckoutRateQuery,
    useGetTransactionRateQuery,
    useGetAllActionsPieChartQuery,
    useGetConversionRateQuery,
    useGetForwardIndicatorOptionsQuery,
    useGetForwardIndicatorsChartQuery,
    useGetCheckIsUsingNewGaTableQuery,
    useGetTopLandingPagesQuery
} = gaFunnelingApi;
export default gaFunnelingApi.reducer;