import React from 'react';
import { Table } from 'antd';
import BudgetTracker from '../../containers/BudgetTracker/BudgetTracker';
import { GenericReportingDropdowns } from '../../containers/GenericReportingDropdowns';

const BudgetPlannerPage = () => {
    return (
        <div>
            <GenericReportingDropdowns includeDateRange={false}></GenericReportingDropdowns>
            <BudgetTracker></BudgetTracker>
        </div>
    );
};

export default BudgetPlannerPage;