import './Layout.css'
import Header from './Header'
import { ConfigProvider } from 'antd'

export default function Layout({ children }) {
    return (
        <>
            <ConfigProvider theme={{
                token: {
                    colorBgContainer: 'var(--secondary-color)',
                    colorBg: 'var(--secondary-color)',
                    colorPrimary: 'var(--primary-color)',
                    colorSecondary: 'var(--secondary-color)',
                    colorLink: 'var(--primary-color)',
                    colorText: 'var(--primary-color)',
                    colorTextLight: 'var(--secondary-color)',
                    colorTextDark: 'var(--primary-color)',
                    borderRadius: '10px',
                    fontFamily: 'apoc',
                },
                components: {
                    Tabs: {
                        itemColor: 'var(--primary-color)',
                        inkBarColor: 'var(--primary-color)',
                        itemActiveColor: 'var(--primary-color)',
                        itemHoverColor: 'grey',
                        horizontalItemPadding: '2rem',
                        fontFamily: 'area-inktrap',
                        colorSecondary: 'var(--primary-color)',
                        colorPrimary: 'var(--primary-color)',
                        colorBg: 'var(--secondary-color)',
                    },
                    Card: {
                        headerBg: 'var(--primary-color)',
                        headerColor: 'var(--secondary-color)',
                        colorBgContainer: 'var(--primary-color)',
                        colorBg: 'var(--primary-color)',
                        colorPrimary: 'var(--secondary-color)',
                        colorText: 'var(--secondary-color)',
                        borderRadius: '10px',
                        fontFamily: 'apoc',
                    },
                    Menu: {
                        itemColor: 'var(--secondary-color)', // Default text color
                        itemBg: 'var(--primary-color)', // Default background
                        fontFamily: 'area-inktrap',
                        subMenuItemBg: 'var(--primary-color)', // Submenu background
                        popupBg: 'var(--primary-color)', // Popup background
                        itemHoverColor: 'var(--dark-green-color)', // Hover text color
                        itemHoverBg: 'var(--tertiary-color)', // Hover background color
                        subMenuItemHoverColor: 'var(--dark-green-color)', // Submenu hover text color
                        subMenuItemHoverBg: 'var(--secondary-color)', // Submenu hover background color
                        itemActiveColor: 'var(--dark-green-color)', // Active text color
                        colorPrimary: 'var(--dark-green-color)',
                        colorSecondary: 'var(--primary-color)',
                        colorBgBase: 'var(--primary-color)',
                        colorBg: 'var(--primary-color)',
                        colorText: 'var(--secondary-color)',
                        // seperator color white for pop up

                    },
                    Table: {
                        headerBg: 'var(--tertiary-color)',
                        headerColor: 'var(--primary-color)',
                        colorBgContainer: 'var(--secondary-color)',
                        colorPrimary: 'var(--primary-color)',
                        colorText: 'var(--orimary-color)',
                        borderRadius: '10px',
                        fontFamily: 'apoc',
                        rowHoverBg: 'var(--tertiary-color)',
                    },
                }
            }
            }>
                <Header />
                <div id='content'>
                    {children}
                </div >
            </ConfigProvider>
        </>
    )
}