import { Select } from "antd";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useGetActiveProductGroupConfigQuery, useListProductGroupConfigsQuery } from "../../redux/api/productGrouping";
import { useState } from "react";
import { Spin } from "antd";
import { useGetLtrByCategoryQuery } from "../../redux/api/customerAnalytics";
import PlotContainer from "../../containers/PlotContainer";
import ProductGroupConfigDropdown from "../ProductGroupConfigDropdown";
import TooltipContainer from "../../components/UI/TooltipContainer";

export default function CategoricalLtr() {

    const selectedClient = useSelector((state) => state.genericReporting.selectedClient);
    const selectedConfig = useSelector((state) => state.genericReporting.selectedConfig);

    return (
        <div className="main-content-container">
            <div className='flexible-container'>
                <div>
                    <h2>Select a Configuration <TooltipContainer title='Select a Configuration'></TooltipContainer> </h2>
                    <ProductGroupConfigDropdown></ProductGroupConfigDropdown>
                </div>
            </div>
            <div className="flexible-container" style={{
                'height': '100vh',
                'width': '95vw',
            }}>
                <PlotContainer
                    tooltipTitle='Lifetime Revenue By Type'
                    useApi={useGetLtrByCategoryQuery}
                    useApiArgs={{
                        clientName: selectedClient,
                        selectedConfig: selectedConfig
                    }}
                ></PlotContainer>
            </div>
        </div>
    );
}