import React from 'react';
import PropTypes from 'prop-types';

import MetricBox from '../UI/MetricBox';

const BudgetTrioBox = ({ channel, actualSpend, recommendedBudget, targetSpend, diffSpend, pacing }) => {
    return (
        <div style={{
            backgroundColor: 'var(--primary-color)',
            padding: '1rem'

        }}>
            <div style={
                {
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                }
            }>
                <MetricBox
                    ignoreTooltips={true}
                    value={
                        <div style={{
                            color: 'var(--primary-color)',
                        }}>
                            <div>{targetSpend}</div>
                        </div>
                    }
                    title={
                        <div style={{
                            color: 'var(--primary-color)',
                        }}>
                            <div>{channel} Target</div>
                        </div>
                    }
                    fill={
                        'var(--white-color)'
                    }
                />
                <MetricBox
                    ignoreTooltips={true}
                    value={
                        <div style={{
                            color: 'var(--primary-color)',
                        }}>
                            <div>{actualSpend}</div>
                        </div>
                    }
                    title={
                        <div style={{
                            color: 'var(--primary-color)',
                        }}>
                            <div>{channel} Spend</div>
                        </div>
                    }
                    fill={
                        'var(--secondary-color)'
                    }
                />
                <MetricBox
                    ignoreTooltips={true}
                    value={
                        <div style={{
                            color: 'var(--primary-color)',
                        }}>
                            <div>{recommendedBudget}</div>
                        </div>
                    }
                    title={
                        <div style={{
                            color: 'var(--tertriary-color)',
                        }}>
                            <div>{channel} Recommended Spend</div>
                        </div>
                    }
                    fill={
                        'var(--tertiary-color)'
                    }
                />
                <MetricBox
                    ignoreTooltips={true}
                    value={
                        <div style={{
                            color: 'var(--primary-color)',
                        }}>
                            <div>{diffSpend}</div>
                        </div>
                    }
                    title={
                        <div style={{
                            color: 'var(--primary-color)',
                        }}>
                            <div>{channel} Diff</div>
                        </div>
                    }
                    fill={
                        'var(--green-color)'
                    }
                />
                <MetricBox
                    ignoreTooltips={true}
                    value={
                        <div style={{
                            color: 'var(--white-color)',
                        }}>
                            <div>{pacing}</div>
                        </div>
                    }
                    title={
                        <div style={{
                            color: 'var(--white-color)',
                        }}>
                            <div>{channel} Pacing</div>
                        </div>
                    }
                    fill={
                        'var(--dark-green-color)'
                    }
                />
            </div>
        </div>
    );
};

BudgetTrioBox.propTypes = {
    channel: PropTypes.string,
    actualSpend: PropTypes.number,
    recommendedBudget: PropTypes.number,
    targetSpend: PropTypes.number,
    diffSpend: PropTypes.number,
    pacing: PropTypes.number,
};

export default BudgetTrioBox;