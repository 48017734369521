import { useGetCompassMultipliersQuery, useGetCompassMediaSpendPerformanceQuery, useGetPercentageContributionByChannelQuery, useGetCompassMultipliersMonthlyQuery } from "../../redux/api/compassProject";
import TableContainer from "../../containers/TableContainer";
import PlotContainer from "../../containers/PlotContainer";
import { useSelector } from "react-redux";

export default function MediaInvestmentPerformance() {

    const selectedClient = useSelector((state) => state.genericReporting.selectedClient);
    const selectedStartDate = useSelector((state) => state.genericReporting.startDate);
    const selectedEndDate = useSelector((state) => state.genericReporting.endDate);

    return (
        <div>
            <h1>Media Investment Performance</h1>
            <div className="full-width-container">
                <PlotContainer
                    tooltipTitle='Media Spend vs. NCPA'
                    useApi={useGetCompassMediaSpendPerformanceQuery}
                    useApiArgs={{ client: selectedClient, startDate: selectedStartDate, endDate: selectedEndDate }}
                ></PlotContainer>
            </div>
            <div className="full-width-container">
                <PlotContainer
                    tooltipTitle='Percentage Contribution by Channel'
                    useApi={useGetPercentageContributionByChannelQuery}
                    useApiArgs={{ client: selectedClient }}
                ></PlotContainer>
            </div>
            <div>
                <h3 style={{
                    'margin': '0',
                    'marginTop': '5vh'
                }}>Compass Multipliers</h3>
                <TableContainer
                    tooltipTitle="Compass Multipliers"
                    useApi={useGetCompassMultipliersQuery}
                    apiParams={{ client: selectedClient }}
                ></TableContainer>
            </div>
            <div>
                <h3 style={{
                    'margin': '0',
                    'marginTop': '5vh'
                }}>Monthly Compass Multipliers</h3>
                <TableContainer
                    tooltipTitle="Compass Multipliers"
                    useApi={useGetCompassMultipliersMonthlyQuery}
                    apiParams={{ client: selectedClient, startDate: selectedStartDate }}
                ></TableContainer>
            </div>
        </div>
    );
}