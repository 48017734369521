import React from 'react';

import { Spin } from 'antd';
import BudgetTrioBox from './BudgetTrioBox';

const BudgetBoxes = ({
    data,
    isFetching,
    isError
}) => {
    return (
        <div>
            {
                isFetching ? <Spin></Spin> :
                    <></>
            }
            {
                isError ? <div>Error</div> :
                    <></>
            }
            {!isFetching && !isError && data && <div style={{
                'padding': '1rem',
                'backgroundColor': 'var(--primary-color)',
                'borderRadius': '10px',
            }}>
                {
                    data.map((box, index) => {
                        return (
                            <BudgetTrioBox
                                key={index}
                                channel={box.channel}
                                actualSpend={box.actual_spend}
                                recommendedBudget={box.recommended_budget_for_tomorrow}
                                targetSpend={box.target_spend}
                                diffSpend={box.diff_spend}
                                pacing={box.pacing}
                            ></BudgetTrioBox>
                        );
                    })
                }
            </div>
            }

        </div>
    );
};

export default BudgetBoxes;