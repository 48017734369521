import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from './baseQuery';

export const genericReportingApi = createApi({
    reducerPath: 'genericReportingApi',
    baseQuery: baseQuery,
    tagTypes: ['GenericReporting', 'ExecutiveSummary', 'ProductGroupConfig', 'ProductGroups', 'MetricOptions'],
    endpoints: (builder) => ({
        getBoxMetrics: builder.query({
            query: ({
                client,
                startDate,
                endDate,
                compareStartDate,
                compareEndDate,
                isAmazon,
                isInfluencer,
                isOutreach,
                useOldPrefix,
                prefixStr
            }) => (useOldPrefix ? '/executive_summary' + (isAmazon ? '_amazon' : '') + (isInfluencer ? '_influencer' : '') + (isOutreach ? '_outreach' : '') : prefixStr) + '/box_metrics' + "?client=" + client + "&start_date=" + startDate + "&end_date=" + endDate + "&compare_start_date=" + compareStartDate + "&compare_end_date=" + compareEndDate,
        }),
        getKlaviyoAcquisition: builder.query({
            query: ({
                client,
                startDate,
                endDate
            }) => '/klaviyo/acqusition' + "?client_name=" + client + "&start_date=" + startDate + "&end_date=" + endDate,
        }),
        getBoxMetricOptions: builder.query({
            query: ({ client, platform }) => '/settings/box_metric_options' + "?client=" + client + "&platform=" + platform,
        }),
        setBoxMetricOptions: builder.mutation({
            query: ({ client, metricTitle, orderNumber, platform }) => ({
                url: '/settings/box_metric_option' + "?client=" + client + "&metric_title=" + metricTitle + "&order_number=" + orderNumber + "&platform=" + platform,
                method: 'PUT',
            }),
            invalidatesTags: ['MetricOptions'],
        }),
        deleteBoxMetricOptions: builder.mutation({
            query: ({ client, metricTitle, orderNumber, platform }) => ({
                url: '/settings/box_metric_option' + "?client=" + client + "&metric_title=" + metricTitle + "&order_number=" + orderNumber + "&platform=" + platform,
                method: 'DELETE',
            }),
            invalidatesTags: ['MetricOptions'],
        }),
        getBoxMetricOptionsTable: builder.query({
            query: ({ client, platform }) => '/settings/configured_box_metric_options_table' + "?client=" + client + "&platform=" + platform,
            providesTags: ['MetricOptions'],
        }),
        getIfIsClientOnTikTok: builder.query({
            query: ({ client }) => '/settings/is_client_on_tiktok' + "?client=" + client,
        }),
        getIfIsClientOnBing: builder.query({
            query: ({ client }) => '/settings/is_client_on_bing' + "?client=" + client,
        }),
    }),
});


export const {
    useGetBoxMetricsQuery,
    useGetKlaviyoAcquisitionQuery,
    useGetBoxMetricOptionsQuery,
    useSetBoxMetricOptionsMutation,
    useDeleteBoxMetricOptionsMutation,
    useGetBoxMetricOptionsTableQuery,
    useGetIfIsClientOnTikTokQuery,
    useGetIfIsClientOnBingQuery,
} = genericReportingApi;

export default genericReportingApi.reducer;