import React from 'react';
import MtdTable from '../../components/BudgetPlanner/MtdTable';

import { useGetMtdTargetTableQuery, useGetDailyTableQuery } from '../../redux/api/budgetTracker';
import { useSelector } from 'react-redux';
import { Tabs } from 'antd';
import TableContainer from '../TableContainer';
import ChannelStatus from './ChannelStatus';

const BudgetTracker = () => {

    const selectedClient = useSelector((state) => state.genericReporting.selectedClient);

    const { data: mtdData, isFetching: isMtdFetching } = useGetMtdTargetTableQuery({
        client: selectedClient,
    });

    const tabs = [
        {
            label: 'Current Status',
            key: 'mtd',
            children: [<MtdTable data={mtdData} isFetching={isMtdFetching}></MtdTable>]
        },
        {
            'label': 'Daily Breakdown',
            'key': 'daily',
            children: [
                <div style={{
                    'width': '95vw'
                }}>
                    <TableContainer
                        useApi={useGetDailyTableQuery}
                        apiParams={{ client: selectedClient }}
                        title="Daily Breakdown"
                        tooltipTitle="WIP"
                        width='95vw'
                    ></TableContainer>
                </div>
            ]
        },
        {
            label: 'Channel Status',
            key: 'channel',
            children: [
                <div>
                    <ChannelStatus></ChannelStatus>
                </div>
            ]
        }
    ]

    return (
        <div className='main-content-container'>
            <Tabs style={{
                'width': '95vw'
            }}>
                {tabs.map((tab) => (
                    <Tabs.TabPane tab={tab.label} key={tab.key}>
                        {tab.children}
                    </Tabs.TabPane>
                ))}
            </Tabs>
        </div>
    );
};

export default BudgetTracker;