import baseQuery from "./baseQuery";
import { createApi } from "@reduxjs/toolkit/query/react";

export const compassProjectApi = createApi({
    tags: ['compassProjectList', 'dataTables', 'dataConfig'],
    reducerPath: 'compassProjectApi',
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        getCompassProjects: builder.query({
            query: () => 'compass_projects',
            providesTags: ['compassProjectList'],
        }),
        createCompassProject: builder.mutation({
            query: ({
                client_name,
                project_name,
                project_description
            }) => ({
                url: 'compass_create_project',
                method: 'PUT',
                body: {
                    'client_name': client_name,
                    'project_name': project_name,
                    'project_description': project_description,
                },
            }),
            invalidatesTags: ['compassProjectList'],
        }),
        deleteCompassProject: builder.mutation({
            query: ({ id }) => ({
                url: `compass_delete_project/` + id,
                method: 'DELETE',
            }),
            invalidatesTags: ['compassProjectList'],
        }),
        getDataTables: builder.query({
            query: ({ project_id, data_type }) => 'compass_' + data_type + '_data/' + project_id,
            providesTags: ['dataTables', 'compassProjectList'],
        }),
        // invalidate the dataTables with an empty query
        invalidateDataTables: builder.mutation({
            query: () => 'compass_projects',
            invalidatesTags: ['dataTables'],
        }),
        setProjectAsActive: builder.mutation({
            query: ({ id, client_name }) => ({
                url: `compass_set_project_as_active/` + id + '?client_name=' + client_name,
                method: 'PUT',
            }),
            invalidatesTags: ['compassProjectList'],
        }),
        getActiveProject: builder.query({
            query: ({ client_name }) => 'compass_active_project?client_name=' + client_name,
            providesTags: ['compassProjectList'],
        }),
        getImpactOverTime: builder.query({
            query: ({ client_name, start_date, end_date, granularity, drivers

            }) => {
                return {
                    url: 'compass_impact_over_time',
                    method: 'POST',
                    body: {
                        'selected_drivers': drivers,
                        'client_name': client_name,
                        'start_date': start_date,
                        'end_date': end_date,
                        'granularity': granularity,
                    }
                }
            }
        }),
        getUniqueDrivers: builder.query({
            query: ({ client_name }) => 'compass_unique_drivers?client_name=' + client_name,
            providesTags: ['compassProjectList'],
        }),
        getDriverSplit: builder.query({
            query: ({ client_name, start_date, end_date, granularity, drivers }) => {
                return {
                    url: 'compass_driver_split',
                    method: 'POST',
                    body: {
                        'client_name': client_name,
                        'start_date': start_date,
                        'end_date': end_date,
                        'granularity': granularity,
                        'selected_drivers': drivers,
                    }
                }
            }
        }),
        getImpactOverviewTable: builder.query({
            query: ({ client_name, start_date, end_date, granularity, drivers }) => {
                return {
                    url: 'compass_impact_overview_table',
                    method: 'POST',
                    body: {
                        'client_name': client_name,
                        'start_date': start_date,
                        'end_date': end_date,
                        'granularity': granularity,
                        'selected_drivers': drivers,
                    }
                }
            }
        }),
        getPaidChannelComparisonPerformanceChart: builder.query({
            query: ({ client_name, start_date, end_date, granularity, drivers }) => {
                return {
                    url: 'compass_paid_channel_performance_comparison_chart',
                    method: 'POST',
                    body: {
                        'client_name': client_name,
                        'start_date': start_date,
                        'end_date': end_date,
                        'granularity': granularity,
                        'selected_drivers': drivers,
                    }
                }
            }
        }),
        getPaidChannelComparisonPerformanceTimeSeries: builder.query({
            query: ({ client_name, start_date, end_date, granularity, drivers }) => {
                return {
                    url: 'compass_paid_channel_performance_comparison_time_series_chart',
                    method: 'POST',
                    body: {
                        'client_name': client_name,
                        'start_date': start_date,
                        'end_date': end_date,
                        'granularity': granularity,
                        'selected_drivers': drivers,
                    }
                }
            }
        }),
        getPaidChannelTable: builder.query({
            query: ({ client_name, start_date, end_date, granularity, drivers }) => {
                return {
                    url: 'compass_paid_channel_table',
                    method: 'POST',
                    body: {
                        'client_name': client_name,
                        'start_date': start_date,
                        'end_date': end_date,
                        'granularity': granularity,
                        'selected_drivers': drivers,
                    }
                }
            }
        }),
        getConsiderationContributors: builder.query({
            query: ({ client_name, start_date, end_date, granularity, drivers }) => {
                return {
                    url: 'compass_consideration_contributors',
                    method: 'POST',
                    body: {
                        'client_name': client_name,
                        'start_date': start_date,
                        'end_date': end_date,
                        'granularity': granularity,
                        'selected_drivers': drivers,
                    }
                }
            }
        }),
        getShortMediumTermImpact: builder.query({
            query: ({ client_name, start_date, end_date, granularity, drivers }) => {
                return {
                    url: 'compass_impacts_short_medium_term_chart',
                    method: 'POST',
                    body: {
                        'client_name': client_name,
                        'start_date': start_date,
                        'end_date': end_date,
                        'granularity': granularity,
                        'selected_drivers': drivers,
                    }
                }
            }
        }),
        getCompassMultipliers: builder.query({
            query: ({ client }) => '/media_investment_performance/compass_multipliers_table?client=' + client,
        }),
        getCompassMultipliersMonthly: builder.query({
            query: ({ client, startDate }) => '/media_investment_performance/compass_multipliers_table_monthly?client=' + client + '&start_date=' + startDate,
        }),
        getCompassMediaSpendPerformance: builder.query({
            query: ({ client, startDate, endDate, granularity }) => '/media_investment_performance/media_spend_vs_ncpa?client=' + client + '&start_date=' + startDate + '&end_date=' + endDate + '&granularity=' + granularity,
        }),
        getPercentageContributionByChannel: builder.query({
            query: ({ client }) => '/media_investment_performance/percentage_contribution_by_channel?client=' + client,
        }),
        getCompassStartEndDates: builder.query({
            query: ({ clientName }) => '/compass_start_end_dates?client_name=' + clientName,
            providesTags: ['compassProjectList'],
        }),
        getCompassConfigOptions: builder.query({
            query: ({ projectId }) => '/compass_config_options/' + projectId,
        }),
        configureCompassProject: builder.mutation({
            query: ({
                projectId, attr_column,
                compass_column, spend_column,
                input_date_column, input_date_format,
                output_date_column, output_date_format
            }) => ({
                url: 'compass_configure_project/' + projectId,
                method: 'PUT',
                body: {
                    'matching_cols': {
                        'attr_column': attr_column,
                        'compass_column': compass_column,
                        'spend_column': spend_column,
                    },

                    'input_date_column': input_date_column,
                    'input_date_format': input_date_format,
                    'output_date_column': output_date_column,
                    'output_date_format': output_date_format,
                },
            }),
            invalidatesTags: ['dataConfig'],
        }),
        getCompassProjectConfig: builder.query({
            query: ({ projectId }) => '/compass_project_data_config/' + projectId,
            providesTags: ['dataConfig'],
        }),
    }),
});

export const {
    useGetCompassProjectConfigQuery,
    useConfigureCompassProjectMutation,
    useGetCompassConfigOptionsQuery,
    useGetCompassProjectsQuery,
    useGetDataTablesQuery,
    useCreateCompassProjectMutation,
    useDeleteCompassProjectMutation,
    useInvalidateDataTablesMutation,
    useSetProjectAsActiveMutation,
    useGetActiveProjectQuery,
    useGetImpactOverTimeQuery,
    useGetUniqueDriversQuery,
    useGetDriverSplitQuery,
    useGetImpactOverviewTableQuery,
    useGetPaidChannelComparisonPerformanceChartQuery,
    useGetPaidChannelComparisonPerformanceTimeSeriesQuery,
    useGetPaidChannelTableQuery,
    useGetConsiderationContributorsQuery,
    useGetShortMediumTermImpactQuery,
    useGetCompassMultipliersQuery,
    useGetCompassMultipliersMonthlyQuery,
    useGetCompassMediaSpendPerformanceQuery,
    useGetPercentageContributionByChannelQuery,
    useGetCompassStartEndDatesQuery,
} = compassProjectApi;

export default compassProjectApi.reducer;