import React, { useEffect, useState } from 'react';
import { Spin, Table } from 'antd';
import './mtdTable.css';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';

const MtdTable = ({ data, isFetching }) => {
    const [formattedColumns, setFormattedColumns] = useState([]);

    useEffect(() => {
        if (data && data.columns && data.dataSource && !isFetching) {
            // Add conditional formatting for percentage values
            const tmpFormattedColumns = data.columns.map((col) => ({
                ...col,
                render: (text, record) => {
                    // Exclude "Period" column and non-percentage cells
                    if (col.dataIndex === 'Period') {
                        return text; // Default rendering for "Period" column
                    }

                    // Check if the value is a percentage and if the row is not "Percentage of Target Reached"
                    if (typeof text === 'string' && text.includes('%') && record.Period !== 'Percentage of Target Reached') {
                        let isPositive;

                        if (col.dataIndex === 'NCPA' || col.dataIndex === 'CPNS') {
                            // Invert logic for NCPA and CPNS
                            isPositive = parseFloat(text.replace('%', '')) <= 0;
                        } else {
                            // Standard logic for other columns
                            isPositive = parseFloat(text.replace('%', '')) > 0;
                        }

                        // isArrowUp
                        const isArrowUp = parseFloat(text.replace('%', '')) > 0;

                        if (col.dataIndex == 'Total Spend') {
                            if (record.Period !== 'Run Rate vs Last Month %') {
                                isPositive = parseFloat(text.replace('%', '')) <= 0;
                            }
                        }

                        // Apply green for positive, red for negative
                        const style = {
                            backgroundColor: isPositive ? '#99ffa3' : '#ff9999',
                            borderRadius: '0.5rem',
                            padding: '0.2rem',
                            flexDirection: 'row',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            maxWidth: '8rem',
                            minWidth: '5rem',
                        };

                        return <span style={style}>
                            {isArrowUp ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
                            {text}
                        </span>;
                    }

                    return text; // Default rendering for other cells
                },
            }));
            setFormattedColumns(tmpFormattedColumns);
        }
    }, [data]);

    // Define row styling based on the "Period" value
    const getRowClassName = (record) => {
        if (record.Period === 'Run Rate vs Last Month %' || record.Period === 'Percentage of Target Reached') {
            return 'secondary-background';
        }
        return '';
    };

    return (
        <div style={{
            width: '95%',
            margin: 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '8vh'
        }}>
            {isFetching && <Spin></Spin>}
            {data && data.columns && data.dataSource && !isFetching && (
                <div style={{
                    width: '100%',
                    margin: 'auto',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    animation: 'fadeIn 1.5s',
                }}>
                    <Table
                        columns={formattedColumns}
                        dataSource={data.dataSource}
                        rowKey="Period" // Ensure each row has a unique key
                        rowClassName={getRowClassName} // Apply row class names
                        // not paginated
                        pagination={false}
                        scroll={{ x: '100%', y: '100%' }} // Scrollable table
                    />
                </div>
            )}
        </div>
    );
};

export default MtdTable;
