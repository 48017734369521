import React, { useEffect, useState } from 'react';
import { useGetUniqueDriversQuery } from '../../redux/api/compassProject';
import { Select } from 'antd';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setDrivers } from '../../redux/store/compassSlice';

const DriversDropdown = () => {

    const dispatch = useDispatch();

    const selectedClient = useSelector((state) => state.genericReporting.selectedClient);
    const selectedDrivers = useSelector((state) => state.compass.drivers);

    const driversData = useGetUniqueDriversQuery({
        client_name: selectedClient
    });

    useEffect(() => {


        dispatch(setDrivers(driversData.data));
    }
        , [driversData.data]);


    return (
        <Select style={{
            width: '30vw'
        }} options={
            driversData.data ? driversData.data.map((driver) => {
                return { label: driver, value: driver }
            }) : []
        }
            mode='multiple'
            placeholder='Select a driver'
            value={selectedDrivers
            }
            onChange={(val) => {
                dispatch(setDrivers(val));
            }}
        >
        </Select>
    );
};

export default DriversDropdown;