import { useGetAllTabsUserHasAccessToQuery } from "../../redux/api/user";
import { useEffect, useState } from "react";
import { Tabs } from "antd";
import { GenericReportingDropdowns } from '../../containers/GenericReportingDropdowns';
import ProductGroupingPage from "../../containers/Settings/ProductGroupingPage";
import SettingsDropdowns from "../../containers/PaidMedia.js/SettingsDropdowns";
import BoxMetricSettings from "../../containers/Settings/BoxMetricsSettingsPage";
import ExtraCostSettingsPage from "../../containers/Settings/ExtraCostSettingsPage";
import BudgetTrackerSettingsPage from "../../containers/Settings/BudgetTrackerSettingsPage";

export default function SettingsPage() {

    const [tabs, setTabs] = useState([]);

    const allowedTabs = useGetAllTabsUserHasAccessToQuery();

    useEffect(() => {
        if (allowedTabs.isError) {
            allowedTabs.refetch()
        }
        if (allowedTabs.isSuccess) {
            var tmpTabs = [];
            for (var i = 0; i <= allowedTabs.data.length; i++) {
                const tab = allowedTabs.data[i];
                if (tab == 'executive_summary') {
                    tmpTabs.push({
                        label: 'Product Grouping (Shopify)',
                        key: 'Product Grouping',
                        children: [<ProductGroupingPage />]
                    });
                    tmpTabs.push({
                        label: 'Box Metrics (Shopify)',
                        key: 'Box Metrics (Shopify)',
                        children: [<BoxMetricSettings platform={'Shopify'} />]
                    });
                }
                else if (tab == 'amazon') {
                    tmpTabs.push({
                        label: 'Product Grouping (Amazon)',
                        key: 'Product Grouping (Amazon)',
                        children: [<ProductGroupingPage platform={'Amazon'} />]
                    });
                    tmpTabs.push({
                        label: 'Box Metrics (Amazon)',
                        key: 'Box Metrics (Amazon)',
                        children: [<BoxMetricSettings platform={'Amazon'} />]
                    });
                }
                else if (tab == 'paid_media') {
                    tmpTabs.push({
                        label: 'Google Settings',
                        key: 'Google Settings',
                        children: [<SettingsDropdowns platform='google' />]
                    });
                    tmpTabs.push({
                        label: 'Facebook Settings',
                        key: 'Facebook Settings',
                        children: [<SettingsDropdowns platform='facebook' />]
                    });
                    tmpTabs.push({
                        label: 'Tiktok Settings',
                        key: 'Tiktok Settings',
                        children: [<SettingsDropdowns platform='tiktok' />]
                    });
                    tmpTabs.push({
                        label: 'Bing Settings',
                        key: 'Bing Settings',
                        children: [<SettingsDropdowns platform='bing' />]
                    });
                }
                if (tab == 'budget_planner' || tab == 'executive_summary') {
                    if (!tmpTabs.some((tab) => tab.label === 'Extra Costs')) {
                        tmpTabs.push({
                            label: 'Extra Costs',
                            key: 'Extra Costs',
                            children: [<ExtraCostSettingsPage />]
                        });
                    }
                }
                if (tab == 'budget_planner') {
                    tmpTabs.push({
                        label: 'Budget Planner',
                        key: 'Budget Planner',
                        children: [<BudgetTrackerSettingsPage />]
                    });
                }
            }
            setTabs(tmpTabs);
        }
    },
        [allowedTabs]);

    return (
        <div className='main-content-container'>
            <GenericReportingDropdowns></GenericReportingDropdowns>
            <Tabs style={{
                'width': '95vw'
            }} items={tabs} ></Tabs>
        </div>
    );
}