import React from 'react';
import { useSelector } from 'react-redux';
import PlotContainer from '../PlotContainer';
import '../containerStyles.css';
import { useState } from 'react';
import IsRollingSwitch from '../../components/UI/IsRollingSwitch';
import {
    useGetAmazonRevenuePlotQuery,
    useGetAmazonOrdersPlotQuery,
    useGetAmazonAovPlotQuery,
    useGetRollingSpendQuery,
    useGetAmazonDtcSpendVsSalesPlotQuery,
    useGetExecutiveSummaryAmazonRoasPlotQuery,
    useGetNewCustomerVsNcpaQuery
} from '../../redux/api/executiveSummary';
import ExecutiveSummaryNewReturningByConfig from './ExecutiveSummaryNewReturningByConfig';

const ExecutiveSummaryCharts = () => {
    const selectedClient = useSelector((state) => state.genericReporting.selectedClient);
    const selectedStartDate = useSelector((state) => state.genericReporting.startDate);
    const selectedEndDate = useSelector((state) => state.genericReporting.endDate);
    const selectedDateGranularity = useSelector((state) => state.genericReporting.dateGranularity);
    const rollingWindow = useSelector((state) => state.genericReporting.rollingWindow);

    const [isRevenueChartRolling, setIsRevenueChartRolling] = useState(false);
    const [isOrdersChartRolling, setIsOrdersChartRolling] = useState(false);
    const [isNcpaRolling, setIsNcpaRolling] = useState(false);
    const [isDtcSpendVsSalesRolling, setIsDtcSpendVsSalesRolling] = useState(false);
    const [isRoasRolling, setIsRoasRolling] = useState(false);

    return (
        <div>
            {/* <div className='full-width-container'>
                <PlotContainer useApi={useGetRollingSpendQuery} tooltipTitle='Total Spend vs 7D Rolling Total Spend' useApiArgs={{
                    client: selectedClient,
                    startDate: selectedStartDate,
                    endDate: selectedEndDate,
                    granularity: selectedDateGranularity,
                    rollingWindow: rollingWindow,
                    platform: 'Amazon'
                }} />
            </div> */}
            {/* <div className='flex-column'>
            <IsRollingSwitch isRolling={isNcpaRolling} setIsRolling={setIsNcpaRolling} />
            <div className='full-width-container' style={{
                'marginTop': '0vh',
                'borderRadius': '0px 10px 10px 10px'
            }}>
                <PlotContainer
                    tooltipTitle='New Customers vs NCPA'
                    useApi={useGetNewCustomerVsNcpaQuery}
                    useApiArgs={{
                        client: selectedClient,
                        startDate: selectedStartDate,
                        endDate: selectedEndDate,
                        granularity: selectedDateGranularity,
                        isRolling: isNcpaRolling,
                        rollingWindow: rollingWindow,
                        platform: 'Amazon'
                    }} />
                </div>
            </div> */}
            {/* <div className='flex-column'>
                <IsRollingSwitch isRolling={isRoasRolling} setIsRolling={setIsRoasRolling} />
                <div className='full-width-container' style={{
                    'marginTop': '0vh',
                    'borderRadius': '0px 10px 10px 10px'
                }}>
                    <PlotContainer
                        tooltipTitle='ROAS Trend'
                        useApi={useGetExecutiveSummaryAmazonRoasPlotQuery}
                        useApiArgs={{
                            client: selectedClient,
                            startDate: selectedStartDate,
                            endDate: selectedEndDate,
                            granularity: selectedDateGranularity,
                            isRolling: isRoasRolling,
                            rollingWindow: rollingWindow
                        }} />
                </div>
            </div> */}
            <div className='flex-column'>
                <IsRollingSwitch isRolling={isRevenueChartRolling} setIsRolling={setIsRevenueChartRolling} />
                <div className='full-width-container' style={{
                    'marginTop': '0vh',
                    'borderRadius': '0px 10px 10px 10px'
                }}>
                    <PlotContainer
                        tooltipTitle='Revenue Breakdown by Customer Type'
                        useApi={
                            useGetAmazonRevenuePlotQuery
                        }
                        useApiArgs={{
                            client: selectedClient,
                            startDate: selectedStartDate,
                            endDate: selectedEndDate,
                            granularity: selectedDateGranularity,
                            isRolling: isRevenueChartRolling,
                            rollingWindow: rollingWindow
                        }} />
                </div>
            </div>
            <div className='flex-column'>
                <IsRollingSwitch isRolling={isOrdersChartRolling} setIsRolling={setIsOrdersChartRolling} />
                <div className='full-width-container' style={{
                    'marginTop': '0vh',
                    'borderRadius': '0px 10px 10px 10px'
                }}>
                    <PlotContainer
                        tooltipTitle='Orders Breakdown by Customer Type'
                        useApi={useGetAmazonOrdersPlotQuery}
                        useApiArgs={{
                            client: selectedClient,
                            startDate: selectedStartDate,
                            endDate: selectedEndDate,
                            granularity: selectedDateGranularity,
                            isRolling: isOrdersChartRolling,
                            rollingWindow: rollingWindow
                        }} />
                </div>
            </div>

            <div className='full-width-container'>
                <PlotContainer
                    tooltipTitle='AOV Trend'
                    useApi={useGetAmazonAovPlotQuery}
                    useApiArgs={{
                        client: selectedClient,
                        startDate: selectedStartDate,
                        endDate: selectedEndDate,
                        granularity: selectedDateGranularity,
                        isRolling: false,
                        rollingWindow: rollingWindow
                    }} />
            </div>
            <div className='flex-column'>
                <IsRollingSwitch isRolling={isDtcSpendVsSalesRolling} setIsRolling={setIsDtcSpendVsSalesRolling} />
                <div className='full-width-container' style={{
                    'marginTop': '0vh',
                    'borderRadius': '0px 10px 10px 10px'
                }}>
                    <PlotContainer
                        tooltipTitle='DTC Spend vs Sales'
                        useApi={useGetAmazonDtcSpendVsSalesPlotQuery}
                        useApiArgs={{
                            client: selectedClient,
                            startDate: selectedStartDate,
                            endDate: selectedEndDate,
                            granularity: selectedDateGranularity,
                            isRolling: isDtcSpendVsSalesRolling,
                            rollingWindow: rollingWindow
                        }} />
                </div>
            </div>
            <ExecutiveSummaryNewReturningByConfig platform='Amazon' />
        </div>
    );
};

export default ExecutiveSummaryCharts;