import { useSelector } from "react-redux";
import PlotContainer from "../PlotContainer";
import { useGetCampaignComparisonPlotQuery } from "../../redux/api/adsReporting";
import { useState } from "react";
import { Select } from "antd";

export default function CampaignComparison({
    platform,
    selectedCampaigns,
}) {

    const selectedClient = useSelector((state) => state.genericReporting.selectedClient);
    const selectedStartDate = useSelector((state) => state.genericReporting.startDate);
    const selectedEndDate = useSelector((state) => state.genericReporting.endDate);
    const granularity = useSelector((state) => state.genericReporting.dateGranularity);
    const [selectedMetric, setSelectedMetric] = useState('CPA');

    const options = [
        {
            'label': 'Spend',
            'value': 'Spend'
        },
        {
            'label': 'Clicks',
            'value': 'Clicks'
        },
        {
            'label': 'Impressions',
            'value': 'Impressions'
        },
        {
            'label': 'Conversions',
            'value': 'Conversions'
        },
        {
            'label': 'CPC',
            'value': 'CPC'
        },
        {
            'label': 'CPM',
            'value': 'CPM'
        },
        {
            'label': 'CPA',
            'value': 'CPA'
        },
        {
            'label': 'CTR',
            'value': 'CTR'
        },
        {
            'label': 'CVR',
            'value': 'CVR'
        }
    ]

    return (
        <div style={{
            "background-color": "var(--tertiary-color)",
            "border-radius": "10px",
            "height": "auto"
        }}>
            <div style={
                {
                    'marginLeft': '2vw'
                }
            }>
                <h3>Select a Metric</h3>
                <Select style={{ width: '10vw' }} value={selectedMetric} onChange={(val) => {
                    setSelectedMetric(val);
                }
                }>
                    {options.map((option) => {
                        return <Select.Option key={option.value} value={option.value}>{option.label}</Select.Option>
                    })}
                </Select>
            </div>
            <div className='full-width-container'>
                <PlotContainer tooltipTitle='Multimetric Comparison' useApi={useGetCampaignComparisonPlotQuery} useApiArgs={{
                    client: selectedClient,
                    startDate: selectedStartDate,
                    endDate: selectedEndDate,
                    selectedCampaigns: selectedCampaigns,
                    platform: platform,
                    granularity: granularity,
                    metric: selectedMetric
                }} />
            </div>
        </div>
    );
}