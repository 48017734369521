import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from './baseQuery';

export const budgetTrackerApi = createApi({

    reducerPath: 'budgetTrackerApi',
    baseQuery: baseQuery,
    tagTypes: ['BudgetTracker'],
    endpoints: (builder) => ({
        getBudgetCategories: builder.query({
            query: ({
                client,
                startDate,
                endDate
            }) => ({
                url: '/budget_tracker/budget_categories',
                method: 'GET',
                params: {
                    client: client,
                    start_date: startDate,
                    end_date: endDate,
                },
            }),
            providesTags: ['BudgetTracker'],
        }),
        updateBudget: builder.mutation({
            query: ({ client, channel, budget }) => ({
                url: '/budget_tracker/budget',
                method: 'PUT',
                params: {
                    client,
                    channel,
                    budget
                },
            }),
            invalidatesTags: ['BudgetTracker'],
        }),
        getBudgets: builder.query({
            query: ({ client }) => ({
                url: '/budget_tracker/budgets',
                method: 'GET',
                params: {
                    client
                },
            }),
            providesTags: ['BudgetTracker'],
        }),
        getMtdTargetTable: builder.query({
            query: ({ client }) => ({
                url: '/budget_tracker/mtd_target_table',
                method: 'GET',
                params: {
                    client: client
                },
            }),
            providesTags: ['BudgetTracker'],
        }),
        getDailyTable: builder.query({
            query: ({ client }) => ({
                url: '/budget_tracker/daily_table',
                method: 'GET',
                params: {
                    client: client
                },
            }),
            providesTags: ['BudgetTracker'],
        }),
        getBudgetBoxes: builder.query({
            query: ({ client }) => ({
                url: '/budget_tracker/budget_boxes',
                method: 'GET',
                params: {
                    client: client
                },
            }),
            providesTags: ['BudgetTracker'],
        }),
        getDailyTableChannels: builder.query({
            query: ({ client }) => ({
                url: '/budget_tracker/daily_table_channels',
                method: 'GET',
                params: {
                    client: client
                },
            }),
            providesTags: ['BudgetTracker'],
        }),
        getUniqueMediaChannels: builder.query({
            query: ({ client }) => ({
                url: '/budget_tracker/unique_media_channels',
                method: 'GET',
                params: {
                    client: client
                },
            }),
            providesTags: ['BudgetTracker'],
        }),
        getSpendVsTargetChart: builder.query({
            query: ({ client, channel }) => ({
                url: '/budget_tracker/spend_vs_target_chart',
                method: 'GET',
                params: {
                    client: client,
                    channel: channel
                },
            }),
            providesTags: ['BudgetTracker'],
        }),
    }),
});


export default budgetTrackerApi.reducer;

export const {
    useGetSpendVsTargetChartQuery,
    useGetUniqueMediaChannelsQuery,
    useGetDailyTableChannelsQuery,
    useGetBudgetBoxesQuery,
    useGetDailyTableQuery,
    useGetBudgetCategoriesQuery,
    useUpdateBudgetMutation,
    useGetBudgetsQuery,
    useGetMtdTargetTableQuery
} = budgetTrackerApi;