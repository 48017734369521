import { React, useEffect } from 'react';
import PlotContainer from './PlotContainer';
import { useSelector, useDispatch } from 'react-redux';
import { useGetKlaviyoAcquisitionQuery } from '../redux/api/genericReporting';
import { setStartAndEndDate } from '../redux/store/genericReportingSlice';
import dayjs from 'dayjs';


const KlaviyoAcquisition = () => {

    const dispatch = useDispatch();

    const selectedClient = useSelector((state) => state.genericReporting.selectedClient);
    const selectedStartDate = useSelector((state) => state.genericReporting.startDate);
    const selectedEndDate = useSelector((state) => state.genericReporting.endDate);

    useEffect(() => {
        // set start and end date to the last year 1st of this month to day
        const date = new Date();
        const lastYear = date.getFullYear() - 1;
        const thisYear = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const startDate = dayjs(`${lastYear}-${month}-01`).format('YYYY-MM-DD');
        const endDate = dayjs(`${thisYear}-${month}-${day}`).format('YYYY-MM-DD');

        const startDateString = dayjs(startDate).format('YYYY-MM-DD');
        const endDateString = dayjs(endDate).format('YYYY-MM-DD');

        dispatch(setStartAndEndDate([startDateString, endDateString]));
    }
        , []);

    return (
        <div>
            <div className='flexible-container' style={{
                'width': '95vw',
                'height': '90vh',
            }}>
                <PlotContainer tooltipTitle='Klaviyo Acquisition' useApi={useGetKlaviyoAcquisitionQuery} useApiArgs={{
                    client: selectedClient,
                    startDate: selectedStartDate,
                    endDate: selectedEndDate
                }} />
            </div>
        </div>
    );
};

export default KlaviyoAcquisition;