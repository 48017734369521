import React, { useEffect, useState } from 'react';
import { Tabs } from 'antd';
import { GenericReportingDropdowns } from '../../containers/GenericReportingDropdowns';
import WhitelistedMediaSettings from '../../containers/WhitelistedMediaComparison/WhitelistedMediaSettings';
import WhitelistedMediaCharts from '../../containers/WhitelistedMediaComparison/WhitelistedMediaCharts';
import { useGetIfIsClientOnTikTokQuery } from '../../redux/api/genericReporting';
import { useSelector, useDispatch } from 'react-redux';
import TooltipContainer from '../UI/TooltipContainer';
import { Select } from 'antd';
import { setWhitelistedMediaGranularityLevel } from '../../redux/store/genericReportingSlice';

export default function WhitelistedMediaComparison() {

    const selectedClient = useSelector((state) => state.genericReporting.selectedClient);
    const selectedGranularityLevel = useSelector((state) => state.genericReporting.whitelistedMediaGranularityLevel);

    const dispatch = useDispatch();

    const isClientOnTiktok = useGetIfIsClientOnTikTokQuery(
        {
            client: selectedClient
        }
    );

    const [tabs, setTabs] = useState([]);


    useEffect(() => {
        console.log(isClientOnTiktok);
        var tmpTabs = [{
            label: 'Facebook Settings',
            key: 'Facebook Settings',
            children: [<WhitelistedMediaSettings platform='facebook'></WhitelistedMediaSettings>]
        },
        {
            label: 'Facebook Charts',
            key: 'Facebook Charts',
            children: [
                <WhitelistedMediaCharts platform='facebook'></WhitelistedMediaCharts>
            ]
        }
        ];
        if (isClientOnTiktok.isSuccess) {
            const data = isClientOnTiktok.data;
            if (data) {
                tmpTabs.push({
                    label: 'Tiktok Settings',
                    key: 'Tiktok Settings',
                    children: [<WhitelistedMediaSettings platform='tiktok'></WhitelistedMediaSettings>]
                });
                tmpTabs.push({
                    label: 'Tiktok Charts',
                    key: 'Tiktok Charts',
                    children: [
                        <WhitelistedMediaCharts platform='tiktok'></WhitelistedMediaCharts>
                    ]
                });
            }
        }
        setTabs(tmpTabs);
    }, [isClientOnTiktok]);

    return (
        <div>
            <GenericReportingDropdowns extraDropdowns={
                [
                    <div className='dark-dropdown-container'>
                        <TooltipContainer style={{
                            'alignSelf': 'flex-end',
                            'margin': '0',
                            'width': '0.8vw',
                            'height': '1.0vh',
                        }} invertColor={true} title='Select Granularity Level'></TooltipContainer>
                        <h3 style={{
                            'color': 'white'
                        }}>
                            Select Granularity Level
                        </h3>
                        <Select onChange={
                            (val) => {
                                dispatch(setWhitelistedMediaGranularityLevel(val));
                            }
                        } value={selectedGranularityLevel} onChange={(value) => dispatch(
                            setWhitelistedMediaGranularityLevel(value)
                        )}>
                            <Select.Option value='username'>Username</Select.Option>
                            <Select.Option value='ad_name'>Ad Name</Select.Option>
                        </Select>
                    </div>,
                ]
            }></GenericReportingDropdowns>
            <div className='main-content-container'>
                <Tabs items={tabs}></Tabs>
            </div>
        </div>
    );
}