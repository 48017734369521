import React from 'react';
import { useSelector } from 'react-redux';
import PlotContainer from '../PlotContainer';
import {
    useGetInfluencerContentCountPlotQuery,
    useGetInfluencerEngagementMetricsPlotQuery,
    useGetReachVsRollingReachPlotQuery,
    useGetNewVsReturningContentPlotQuery,
    useGetNewVsReturningReachPlotQuery,
    useGetReachByCreatorTypePlotQuery,
    useGetContentCountByCreatorTypePlotQuery
} from '../../redux/api/archiveInfluencer';

const InfluencerCharts = () => {

    const selectedClient = useSelector((state) => state.genericReporting.selectedClient);
    const selectedStartDate = useSelector((state) => state.genericReporting.startDate);
    const selectedEndDate = useSelector((state) => state.genericReporting.endDate);
    const selectedDateGranularity = useSelector((state) => state.genericReporting.dateGranularity);
    const rollingWindow = useSelector((state) => state.genericReporting.rollingWindow);
    const isRolling = useSelector((state) => state.genericReporting.IsRolling);

    return (
        <div>
            <div className='full-width-container'>
                <PlotContainer useApi={useGetReachVsRollingReachPlotQuery} tooltipTitle='Total Spend vs 7D Rolling Total Spend' useApiArgs={{
                    client: selectedClient,
                    startDate: selectedStartDate,
                    endDate: selectedEndDate,
                    granularity: selectedDateGranularity,
                    rollingWindow: rollingWindow,
                    isRolling: isRolling,
                }} />
            </div>
            <div className='full-width-container'>
                <PlotContainer useApi={useGetInfluencerContentCountPlotQuery} tooltipTitle='Total Spend vs 7D Rolling Total Spend' useApiArgs={{
                    client: selectedClient,
                    startDate: selectedStartDate,
                    endDate: selectedEndDate,
                    granularity: selectedDateGranularity,
                    rollingWindow: rollingWindow,
                    isRolling: isRolling,
                }} />
            </div>
            <div className='full-width-container'>
                <PlotContainer useApi={useGetInfluencerEngagementMetricsPlotQuery} tooltipTitle='Total Spend vs 7D Rolling Total Spend' useApiArgs={{
                    client: selectedClient,
                    startDate: selectedStartDate,
                    endDate: selectedEndDate,
                    granularity: selectedDateGranularity,
                    rollingWindow: rollingWindow,
                    isRolling: isRolling,
                }} />
            </div>
            <div className='full-width-container'>
                <PlotContainer useApi={useGetNewVsReturningReachPlotQuery} tooltipTitle='Total Spend vs 7D Rolling Total Spend' useApiArgs={{
                    client: selectedClient,
                    startDate: selectedStartDate,
                    endDate: selectedEndDate,
                    granularity: selectedDateGranularity,
                    rollingWindow: rollingWindow,
                    isRolling: isRolling,
                }} />
            </div>
            <div className='full-width-container'>
                <PlotContainer useApi={useGetNewVsReturningContentPlotQuery} tooltipTitle='Total Spend vs 7D Rolling Total Spend' useApiArgs={{
                    client: selectedClient,
                    startDate: selectedStartDate,
                    endDate: selectedEndDate,
                    granularity: selectedDateGranularity,
                    rollingWindow: rollingWindow,
                    isRolling: isRolling,
                }} />
            </div>
            <div className='full-width-container'>
                <PlotContainer useApi={useGetReachByCreatorTypePlotQuery} tooltipTitle='Total Spend vs 7D Rolling Total Spend' useApiArgs={{
                    client: selectedClient,
                    startDate: selectedStartDate,
                    endDate: selectedEndDate,
                    granularity: selectedDateGranularity,
                    rollingWindow: rollingWindow,
                    isRolling: isRolling,
                }} />
            </div>
            <div className='full-width-container'>
                <PlotContainer useApi={useGetContentCountByCreatorTypePlotQuery} tooltipTitle='Total Spend vs 7D Rolling Total Spend' useApiArgs={{
                    client: selectedClient,
                    startDate: selectedStartDate,
                    endDate: selectedEndDate,
                    granularity: selectedDateGranularity,
                    rollingWindow: rollingWindow,
                    isRolling: isRolling,
                }} />
            </div>
        </div>
    );
};

export default InfluencerCharts;