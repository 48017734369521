import { useDispatch, useSelector } from "react-redux";
import { setSelectedConfig } from "../redux/store/genericReportingSlice";
import { Select } from "antd";
import { useGetActiveProductGroupConfigQuery, useListProductGroupConfigsQuery } from "../redux/api/productGrouping";
import { useEffect } from "react";
import { Spin } from "antd";


export default function ProductGroupConfigDropdown({
    platform = 'Shopify'
}) {

    const dispatch = useDispatch();

    const selectedClient = useSelector((state) => state.genericReporting.selectedClient);
    const selectedConfig = useSelector((state) => state.genericReporting.selectedConfig);

    const activeConfig = useGetActiveProductGroupConfigQuery({
        client: selectedClient,
        platform: platform
    });

    const configOptions = useListProductGroupConfigsQuery(
        {
            client: selectedClient,
            platform: platform
        }
    );


    useEffect(() => {
        if (activeConfig.status === 'fulfilled') {
            dispatch(setSelectedConfig(activeConfig.data));
        }
    }, [activeConfig, configOptions, selectedClient]);


    return (
        <> {configOptions.data ?
            <Select value={selectedConfig}
                style={{
                    'width': '10vw'
                }}
                options={configOptions.data.map((config) => {
                    return {
                        'label': config,
                        'value': config
                    }
                }
                )}
                onChange={(value) => {
                    dispatch(setSelectedConfig(value));
                }
                }
            ></Select> : <Spin></Spin>
        }
        </>
    )

}