import { Switch } from "antd";

export default function IsRollingSwitch({ isRolling, setIsRolling, style = {}, switchStyle = {} }) {

  const combinedStyle = Object.keys(style).length ? style : {
    'display': 'flex',
    'justifyContent': 'center',
    'alignItems': 'center',
    'marginTop': '1vh',
    'marginLeft': '0.5vw',
    'backgroundColor': 'var(--tertiary-color)',
    'width': '10vw',
    'height': '6vh',
    'borderRadius': '10px 10px 0px 0px',
    'marginBottom': '0vh'
  };

  return (
    <div style={combinedStyle}>
      <Switch
        style={switchStyle}
        checked={isRolling}
        onChange={(checked) => setIsRolling(checked)}
        checkedChildren="Rolling"
        unCheckedChildren="Not Rolling"
      />
    </div>
  );
}