import React from "react";

import { GenericReportingDropdowns } from "../containers/GenericReportingDropdowns";
import { Tabs } from "antd";
import KlaviyoAcquisition from "../containers/KlaviyoAcquisition";

export default function KlaviyoAcquisitionPage() {

    const tabs = [
        {
            label: 'Klaviyo Acquisition',
            key: 'Klaviyo Acquisition',
            children: [<KlaviyoAcquisition></KlaviyoAcquisition>]
        },
    ]

    return (
        <div>
            <div className="main-content-container">
                <GenericReportingDropdowns></GenericReportingDropdowns>
                <Tabs style={{
                    'width': '95vw'
                }} items={tabs} ></Tabs>
            </div>
        </div>
    )
}