import baseQuery from "./baseQuery";
import { createApi } from "@reduxjs/toolkit/query/react";

export const extraCostsApi = createApi({
    reducerPath: "extraCostsApi",
    baseQuery: baseQuery,
    tagTypes: ["ExtraCost"],
    endpoints: (builder) => ({
        getExtraCosts: builder.query({
            query: ({
                client
            }) => ({
                url: `/settings/extra_spend`,
                method: "GET",
                params: {
                    client
                },
            }),
            providesTags: ["ExtraCost"],
        }),
        putExtraCosts: builder.mutation({
            query: ({
                client,
                channel,
                start_date,
                end_date,
                daily_amount,
            }) => ({
                url: `/settings/extra_spend`,
                method: "PUT",
                params: {
                    client,
                    channel,
                    start_date,
                    end_date,
                    daily_amount
                },
            }),
            invalidatesTags: ["ExtraCost"]
        }),
        deleteExtraCosts: builder.mutation({
            query: ({ client, channel, start_date, end_date, daily_amount }) => ({
                url: `/settings/extra_spend`,
                method: "DELETE",
                params: {
                    client,
                    channel,
                    start_date,
                    end_date,
                    daily_amount
                },
            }),
            invalidatesTags: ["ExtraCost"]
        }),
    }),
});

export default extraCostsApi.reducer;

export const { useGetExtraCostsQuery,
    usePutExtraCostsMutation,
    useDeleteExtraCostsMutation
} = extraCostsApi;