import React from 'react';
import { Select } from 'antd';
import { useState } from 'react';
import { useGetCohortedRetentionQuery } from '../../redux/api/customerAnalytics';
import PlotContainer from '../PlotContainer';
import { useSelector } from 'react-redux';
import TooltipContainer from '../../components/UI/TooltipContainer';


const CohortedRetention = () => {

    const [filterType, setFilterType] = useState('All');
    const selectedClient = useSelector((state) => state.genericReporting.selectedClient);

    return (
        <div className='main-content-container'>
            <div className='flexible-container'>
                <div>
                    <h2>Filter By <TooltipContainer title='Filter By'></TooltipContainer></h2>
                    <Select style={{
                        'width': '10vw'
                    }}
                        options={
                            [
                                { label: 'All', value: 'All' },
                                { label: 'OTP', value: 'OTP' },
                                { label: 'Subscription', value: 'Subscription' }
                            ]
                        }
                        onChange={
                            (value) => {
                                setFilterType(value);
                            }
                        }
                        value={filterType}
                    ></Select>
                </div>
            </div>
            <div className='flexible-container' style={{
                'width': '95vw',
                'height': '170vh',
            }}>
                <PlotContainer tooltipTitle='Cohorted Retention' useApi={useGetCohortedRetentionQuery} useApiArgs={{
                    filterType: filterType,
                    client: selectedClient
                }} />
            </div>
        </div>
    );
};

export default CohortedRetention;