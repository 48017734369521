import React from 'react';
import CompassProjects from '../containers/Compass/CompassProjects';
import CompassTabs from '../containers/Compass/CompassTabs';
import { GenericReportingDropdowns } from '../containers/GenericReportingDropdowns';
import DateGranularityDropdown from '../components/UI/DateGranularityDropdown';
import DriversDropdown from '../containers/Compass/DriversDropdown';

const Compass = () => {
    return (
        <>
            <div className='main-content-container'>
                <GenericReportingDropdowns extraDropdowns={
                    [
                        <div className='dark-dropdown-container'>
                            <h3 style={{
                                'color': 'white'
                            }}>Filter By Date Granularity</h3>
                            <DateGranularityDropdown defaultValue="week" />
                        </div>,
                        <div className='dark-dropdown-container'>
                            <h3 style={{
                                'color': 'white'
                            }}>Filter By Drivers</h3>
                            <DriversDropdown />
                        </div>
                    ]
                } />
            </div>
            <div>
                <div className='main-content-container'>
                    <CompassTabs />
                </div>
            </div>
        </>
    );
};

export default Compass;